import React, { useMemo } from 'react'

import { CATEGORIES_ORDER, WIDGET_CATEGORIES } from 'features/views/LayoutEditor/constants'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetCategory, WidgetSchema } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { Box } from 'ui/components/Box'

import { useLayoutEditorWidgetPickerDnDDisplay } from './hooks/useLayoutEditorWidgetPickerDnDDisplay'
import { LayoutEditorCollapsibleControl } from './LayoutEditorCollapsibleControl'
import { LayoutEditorWidgetPickerDisplay } from './LayoutEditorWidgetPickerDisplay'

type Widget = [string, WidgetSchema]
type WidgetCategoryMapping = [WidgetCategory, Widget[]]

type LayoutEditorWidgetPickerProps = {
    onClickWidget?: (widgetType: string) => void
}

export const LayoutEditorWidgetPicker: React.FC<LayoutEditorWidgetPickerProps> = ({
    onClickWidget,
}) => {
    const { schema } = useLayoutEditorContext()

    const categorizedWidgets = useMemo(() => {
        const entries = Object.entries(schema.widgets).reduce(
            (acc, [widgetType, widget]) => {
                const categoryId = widget.category
                if (categoryId === 'hidden') return acc

                if (!acc[categoryId]) acc[categoryId] = []

                acc[categoryId].push([widgetType, widget])

                return acc
            },
            {} as Record<WidgetCategory, Widget[]>
        )

        return Object.entries(entries).sort((a, b) => {
            const categoryIdA = a[0] as WidgetCategory
            const categoryIdB = b[0] as WidgetCategory

            return CATEGORIES_ORDER[categoryIdA] - CATEGORIES_ORDER[categoryIdB]
        }) as WidgetCategoryMapping[]
    }, [schema.widgets])

    return (
        <Box flex column gap="l">
            {categorizedWidgets.map(([categoryId, widgets], idx) => (
                <React.Fragment key={categoryId}>
                    {idx !== 0 && <LayoutEditorControlSeparator />}
                    <LayoutEditorCollapsibleControl
                        key={categoryId}
                        label={WIDGET_CATEGORIES[categoryId].label}
                    >
                        <Box
                            display="grid"
                            style={{ gridTemplateColumns: '1fr 1fr' }}
                            columnGap="m"
                            rowGap="l"
                        >
                            {widgets.map(([widgetType, widget]) => (
                                <LayoutEditorWidgetPickerDnDDisplay
                                    key={widgetType}
                                    widgetSchema={widget}
                                    widgetType={widgetType}
                                    onClick={
                                        onClickWidget ? () => onClickWidget(widgetType) : undefined
                                    }
                                />
                            ))}
                        </Box>
                    </LayoutEditorCollapsibleControl>
                </React.Fragment>
            ))}
        </Box>
    )
}

type LayoutEditorWidgetPickerDnDDisplayProps = React.ComponentPropsWithoutRef<
    typeof LayoutEditorWidgetPickerDisplay
> & {
    widgetType: string
}

const LayoutEditorWidgetPickerDnDDisplay: React.FC<LayoutEditorWidgetPickerDnDDisplayProps> = ({
    widgetType,
    ...props
}) => {
    const { attributes, listeners, ref } = useLayoutEditorWidgetPickerDnDDisplay({
        widgetType,
    })

    return (
        <LayoutEditorWidgetPickerDisplay
            ref={ref}
            {...attributes}
            {...listeners}
            style={{
                touchAction: 'none',
            }}
            {...props}
        />
    )
}
