import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useAgentSkills } from 'data/hooks/agents/skills'
import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'

import { EditEnabledSkills } from '../skills/EditEnabledSkills'
import { EditTools } from '../tools/EditTools'

export function EditAgentInstructionsForm({ agentSid }: { agentSid: string }) {
    const { register } = useFormContext<Partial<Agent>>()
    const { data: skills } = useAgentSkills(agentSid)
    return (
        <Box flex column gap="2xl" maxWidth="full">
            <Box flex column gap="s">
                <Headline size="xs">Core instructions</Headline>
                <Textarea
                    label="Custom Instructions"
                    placeholder="Enter custom instructions"
                    {...register('custom_instructions')}
                    resizable
                    style={{ height: '200px' }}
                />
            </Box>

            <Box flex column gap="s">
                <Headline size="xs">Core skills</Headline>
                {skills && <EditEnabledSkills agentSid={agentSid} skills={skills} />}
            </Box>

            <Box flex column gap="s">
                <Headline size="xs">Core tools</Headline>
                <EditTools agentSid={agentSid} />
            </Box>
        </Box>
    )
}
