import React from 'react'

import {
    FieldsWidgetFieldLabelStyles,
    FieldsWidgetFieldValueStyle,
} from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { useBaseAttributeState } from './hooks/useBaseAttributeState'
import { FieldsWidgetAttributeProps } from './types'

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Box> &
    FieldsWidgetAttributeProps<any> & {
        isEmpty?: boolean
    }

export const BaseAttribute = React.forwardRef<HTMLDivElement, BaseAttributeProps>(
    function BaseAttribute(
        { field, isEmpty, labelPlacement, showFieldIcon, isEditing, children, ...props },
        ref
    ) {
        const { fieldIcon, emptyPlaceholder } = useBaseAttributeState({ field, showFieldIcon })

        const isLabelLeft =
            labelPlacement === 'left' || (labelPlacement === 'hide' && showFieldIcon)
        const isLabelTop = labelPlacement === 'top' || !isLabelLeft

        return (
            <Box
                ref={ref}
                flex
                fontFamily="body"
                fontSize="bodyM"
                fontWeight="bodyRegular"
                lineHeight="bodyM"
                color="text"
                column={isLabelTop}
                alignItems="flex-start"
                gap={isLabelLeft ? 'm' : 0}
                trim={!isEditing}
                {...props}
            >
                {(labelPlacement !== 'hide' || showFieldIcon) && (
                    <Box
                        flex
                        center
                        gap="s"
                        shrink
                        trim
                        className={FieldsWidgetFieldLabelStyles.styleFunction({
                            isLabelTop,
                            hasLabel: labelPlacement !== 'hide',
                            hasIcon: showFieldIcon,
                        })}
                    >
                        {!!fieldIcon && <Icon {...fieldIcon} size="l" color="icon" noShrink />}
                        {labelPlacement !== 'hide' && (
                            <Body size="m" color="text" weight="bold" trim>
                                {field.field.label}
                            </Body>
                        )}
                    </Box>
                )}
                <Box
                    flex
                    center
                    gap="s"
                    pl={isLabelTop && showFieldIcon ? '3xl' : 0}
                    shrink
                    width="full"
                    className={FieldsWidgetFieldValueStyle}
                >
                    {isEmpty ? (
                        <Body size="m" color="textWeakest">
                            {emptyPlaceholder}
                        </Body>
                    ) : (
                        children
                    )}
                </Box>
            </Box>
        )
    }
)
