import React from 'react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
    DropdownTitle,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useLayoutEditorNewWidgetDropdownState } from './hooks/useLayoutEditorNewWidgetDropdownState'

type LayoutEditorNewWidgetDropdownProps = React.ComponentPropsWithoutRef<typeof DropdownButton> & {
    onClickWidget: (widgetType: string) => void
}

export const LayoutEditorNewWidgetDropdown: React.FC<LayoutEditorNewWidgetDropdownProps> = ({
    onClickWidget,
    ...props
}) => {
    const { groups } = useLayoutEditorNewWidgetDropdownState()

    return (
        <Dropdown>
            <DropdownButton {...props} />
            <DropdownContent
                style={{
                    minWidth: '190px',
                }}
                onClick={stopPropagation}
                onCloseAutoFocus={stopPropagation}
            >
                {groups.map((g, idx) => (
                    <React.Fragment key={g.label}>
                        {idx > 0 && <DropdownSeparator />}
                        <DropdownTitle>{g.label}</DropdownTitle>
                        {g.widgets.map((w) => (
                            <DropdownItem
                                key={w.value}
                                label={w.label}
                                onClick={() => onClickWidget(w.value)}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </DropdownContent>
        </Dropdown>
    )
}
