import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { StandardComponentProps } from 'ui/helpers/styles'

type ZoneLogoProps = StandardComponentProps & {
    zone: AccountZone
    isSidebar?: boolean
    style?: React.CSSProperties
}

export function ZoneLogo({ zone, isSidebar = false, ...props }: ZoneLogoProps) {
    const src = zone.logo_url || zone.logo_icon_url
    if (src) {
        return <Box as="img" src={src} alt={zone.display_name} {...props} />
    }

    return (
        <Headline
            size={isSidebar ? 'xs' : 'l'}
            maxLength={isSidebar ? 19 : undefined}
            textAlign="center"
            {...props}
        >
            {zone.display_name}
        </Headline>
    )
}
