import React, { useCallback, useEffect, useRef, useState } from 'react'

import { AuthResult } from '@nangohq/frontend'
import * as Sentry from '@sentry/react'

import { useCreateDataConnection } from 'data/hooks/dataConnections/useCreateDataConnection'
import { useCreateNangoSessionToken } from 'data/hooks/dataConnections/useCreateNangoSessionToken'
import {
    invalidateDataConnections,
    useDataConnections,
} from 'data/hooks/dataConnections/useDataConnections'
import { useEditDataConnection } from 'data/hooks/dataConnections/useEditDataConnection'
import { invalidateExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import {
    ExternalDatabase,
    invalidateExternalDatabases,
    useExternalDatabases,
} from 'data/hooks/dataConnections/useExternalDatabases'
import { usePopulateUserInfoForExtConnection } from 'data/hooks/dataConnections/usePopulateUserInfoForExtConnection'
import { useReauthenticateConnection } from 'data/hooks/dataConnections/useReauthenticateConnection'
import { useValidateAirtableConnection } from 'data/hooks/dataConnections/useValidateAirtableConnection'
import {
    DATA_CONNECTION_DETAIL_MODAL_KEY,
    INTEGRATION_ID_TO_DC_TYPE,
    TRANSLATIONS,
} from 'features/DataConnections/constants'
import { getNangoClient } from 'features/DataConnections/getNangoClient'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'

import { AirtableOauthConfirmation } from './AirtableOauthConfirmation'
import { ExternalAccountSelect } from './ExternalAccountSelect'
import { ExternalDatabaseSelect, SelectedDatabaseType } from './ExternalDatabaseSelect'
import { ExternalObjectSelect } from './ExternalObjectSelect'

export const DataConnectionDetailModal: React.FC = () => {
    const { isOpen, data, setIsOpen } = useModalToggle<{
        initialDataConnection?: DataConnectionDto
        initialSelectedExternalAccountId?: string
    }>(DATA_CONNECTION_DETAIL_MODAL_KEY)

    const { isLoading: isLoadingDataConnections, data: dataConnections } = useDataConnections()
    // TODO-Nango: replace with provided prop when we support more than one integration
    const integrationId = 'airtable'

    // the external account to create new data connection for (the value will be the nango connection id)
    const [selectedExternalAccountId, setSelectedExternalAccountId] = useState<string>('')
    const [selectedDatabase, setSelectedDatabase] = useState<SelectedDatabaseType>({
        id: '',
        name: '',
    })
    const [selectedExternalObjectIds, setSelectedExternalObjectIds] = useState<Set<string>>(
        new Set()
    )
    const [alreadySyncedObjectIds, setAlreadySyncedObjectIds] = useState<Set<string>>(new Set())

    const [dataConnection, setDataConnection] = useState<DataConnectionDto | undefined>(
        data?.initialDataConnection
    )
    const [error, setError] = useState<string>('')
    const [showOauthConfirmation, setShowOauthConfirmation] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState(false)
    const oauthConfirmedCallback = useRef<() => void>()

    const { data: externalDatabases, isLoading: isLoadingExternalDatabases } = useExternalDatabases(
        { integrationId, nangoConnectionId: dataConnection?.nango_connection_id ?? '' },
        {
            enabled: !!dataConnection?.nango_connection_id,
            // if a different account is selected we want to go into a loading state and stop showing stale data immediately
            keepPreviousData: false,
        }
    )
    const { mutateAsync: createSessionToken, isLoading: isCreatingSessionToken } =
        useCreateNangoSessionToken({
            onError: () => {
                toast({
                    title: 'There was a problem setting up a new connection. Please try again or contact support.',
                    type: 'error',
                })
            },
        })

    const { reauthenticateConnection, isCreatingReconnectSessionToken } =
        useReauthenticateConnection()

    useEffect(() => {
        // if modal is opened with an account to be preselected and nothing is selected, select that account
        if (data?.initialSelectedExternalAccountId && !selectedExternalAccountId) {
            setSelectedExternalAccountId(data?.initialSelectedExternalAccountId)
        }
    }, [data?.initialSelectedExternalAccountId, selectedExternalAccountId])

    useEffect(() => {
        if (data?.initialDataConnection) {
            setSelectedExternalAccountId(data.initialDataConnection.nango_connection_id ?? '')
            setSelectedDatabase({
                id: data.initialDataConnection.external_database_id ?? '',
                name: '',
            })
            setDataConnection(data.initialDataConnection)
        }
    }, [data?.initialDataConnection])

    useEffect(() => {
        const matchingDc = dataConnections?.find(
            (dc) => dc.external_database_id === selectedDatabase?.id
        )
        if (selectedDatabase?.id && matchingDc && matchingDc._sid !== dataConnection?._sid) {
            setDataConnection(matchingDc)
        }
    }, [dataConnection, dataConnections, selectedDatabase?.id])

    useEffect(() => {
        if (dataConnection) {
            setSelectedExternalObjectIds(new Set(dataConnection.external_object_ids ?? []))
            setAlreadySyncedObjectIds(new Set(dataConnection.external_object_ids ?? []))
        }

        if (dataConnection && dataConnection.nango_connection_id !== selectedExternalAccountId) {
            setError('This data source is already set up under a different account.')
        } else {
            setError('')
        }
    }, [dataConnection, selectedExternalAccountId])

    useEffect(() => {
        if (!isLoadingExternalDatabases && externalDatabases) {
            const db = externalDatabases.find(
                (x: ExternalDatabase) => x.id === dataConnection?.external_database_id
            )
            setSelectedDatabase({
                id: dataConnection?.external_database_id ?? '',
                name: db?.name ?? '',
            })
        }
    }, [dataConnection, externalDatabases, isLoadingExternalDatabases])

    const handleClose = useCallback(() => {
        data.initialSelectedExternalAccountId = ''
        setSelectedExternalAccountId('')
        setSelectedDatabase({ id: '', name: '' })
        setSelectedExternalObjectIds(new Set())
        setAlreadySyncedObjectIds(new Set())
        setDataConnection(undefined)
        setShowOauthConfirmation(false)
        setIsOpen(false)
    }, [data, setIsOpen])

    const handleOnOpenChange = useCallback(
        (open: boolean) => {
            if (open) {
                setIsOpen(true)
            } else {
                handleClose()
            }
        },
        [handleClose, setIsOpen]
    )

    const handleSelectExtAccountId = (newExternalAccountId: string) => {
        setSelectedExternalAccountId(newExternalAccountId)
        setSelectedDatabase({ id: '', name: '' })
        setSelectedExternalObjectIds(new Set())
    }

    const toast = useToast()
    const { mutateAsync: createDataConnection } = useCreateDataConnection()
    const { mutateAsync: editDataConnection } = useEditDataConnection({
        onError: () => {
            toast({
                title: 'There was a problem updating the data connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { mutateAsync: validateAirtableConnection, isLoading: isValidatingAirtableConnection } =
        useValidateAirtableConnection()

    const { mutateAsync: populateUserInfoInConnection, isLoading: isPopulatingUserInfo } =
        usePopulateUserInfoForExtConnection({
            onError: () => {
                toast({
                    title: 'There was a problem populating user info for the connection created',
                    type: 'error',
                })
            },
        })

    const handleAddNewExternalAccountConfirmed = useCallback(async () => {
        let authResult: AuthResult
        try {
            const sessionToken = await createSessionToken()
            const nango = getNangoClient(integrationId, sessionToken.token)

            authResult = await nango.auth(integrationId)
        } catch (err) {
            console.error('nango auth error:', err)
            Sentry.captureException(err)

            toast({
                title: 'There was a problem authenticating your connection. Please try again later.',
                type: 'error',
            })
            return
        }

        await populateUserInfoInConnection({
            nangoConnectionId: authResult.connectionId,
            integrationId: integrationId,
        })

        if (integrationId === 'airtable') {
            // for airtable nango connections, we only allow nango connection per external user, so we need to
            // verify this is not a duplicate and delete it, if it is a duplicate
            await validateAirtableConnection({
                nangoConnectionId: authResult.connectionId,
                integrationId: integrationId,
            })
        }

        await invalidateExtDataIntegrations()
        handleSelectExtAccountId(authResult.connectionId)
        toast({ title: 'Connection added successfully.', type: 'success' })
    }, [createSessionToken, populateUserInfoInConnection, toast, validateAirtableConnection])

    const handleAddNewExternalAccount = useCallback(async () => {
        // for airtable integration we show a confirmation stage with notes on which scopes to provide before
        // redirecting user to airtable OAuth screen
        if (integrationId === 'airtable') {
            setShowOauthConfirmation(true)
            oauthConfirmedCallback.current = handleAddNewExternalAccountConfirmed
        } else {
            await handleAddNewExternalAccountConfirmed()
        }
    }, [integrationId, setShowOauthConfirmation, handleAddNewExternalAccountConfirmed])

    const handleAddExternalDatabaseConfirmed = useCallback(async () => {
        // we're re-authorising the existing connection in this flow to give access to different set of external DBs
        await reauthenticateConnection({
            nangoConnectionId: selectedExternalAccountId,
            integrationId,
        })
        await invalidateExternalDatabases()
    }, [reauthenticateConnection, selectedExternalAccountId])

    const onSave = useCallback(async () => {
        setIsSaving(true)
        if (!dataConnection) {
            // Creating a new connection
            await createDataConnection({
                label: selectedDatabase.name,
                type: INTEGRATION_ID_TO_DC_TYPE[integrationId],
                nango_connection_id: selectedExternalAccountId,
                external_database_id: selectedDatabase.id,
                external_object_ids: Array.from(selectedExternalObjectIds),
            }).finally(() => setIsSaving(false))
        } else {
            await editDataConnection({
                _sid: dataConnection?._sid ?? '',
                external_object_ids: Array.from(selectedExternalObjectIds),
            }).finally(() => setIsSaving(false))
            // Updating an existing connection
        }
        invalidateDataConnections()
        handleClose()
    }, [
        createDataConnection,
        dataConnection,
        editDataConnection,
        handleClose,
        integrationId,
        selectedDatabase.id,
        selectedDatabase.name,
        selectedExternalAccountId,
        selectedExternalObjectIds,
    ])

    const handleAddExternalDatabase = useCallback(async () => {
        if (integrationId === 'airtable') {
            setShowOauthConfirmation(true)
            oauthConfirmedCallback.current = handleAddExternalDatabaseConfirmed
        } else {
            await handleAddExternalDatabaseConfirmed()
        }
    }, [handleAddExternalDatabaseConfirmed])

    const DataConnectionDetailModalContent: React.FC = () => {
        return (
            <>
                <ModalHeader title="Add data source" showCloseButton={true} />
                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            Account
                        </Body>
                    </Box>
                    <ExternalAccountSelect
                        externalIntegrationId={integrationId}
                        value={selectedExternalAccountId}
                        onChange={handleSelectExtAccountId}
                        onAddExternalAccount={handleAddNewExternalAccount}
                        isLoading={
                            isValidatingAirtableConnection ||
                            isPopulatingUserInfo ||
                            isCreatingSessionToken
                        }
                    />
                </Box>

                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            {TRANSLATIONS[integrationId].Database}
                        </Body>
                    </Box>
                    <ExternalDatabaseSelect
                        externalIntegrationId={integrationId}
                        nangoConnectionId={selectedExternalAccountId}
                        isDisabled={!selectedExternalAccountId}
                        isLoading={isCreatingReconnectSessionToken}
                        onAddExternalDatabase={async () => {
                            await handleAddExternalDatabase()
                        }}
                        selectedDatabase={selectedDatabase}
                        onSelectedDatabase={(newDb) => {
                            setSelectedExternalObjectIds(new Set())
                            setSelectedDatabase(newDb)
                        }}
                    />
                </Box>

                {!!selectedExternalAccountId && !!selectedDatabase.id && (
                    <Box pb="xl" px="3xl">
                        <ExternalObjectSelect
                            externalIntegrationId={integrationId}
                            nangoConnectionId={selectedExternalAccountId}
                            externalDatabaseId={selectedDatabase.id}
                            selectedExternalObjectIds={selectedExternalObjectIds}
                            setSelectedExternalObjectIds={setSelectedExternalObjectIds}
                            forcedObjectIds={alreadySyncedObjectIds}
                        />
                    </Box>
                )}

                {/*TODO-Nango capture and display errors */}
                {error && (
                    <Box px="3xl">
                        <Body color="textError" size="s" mt="m" weight="medium">
                            {error}
                        </Body>
                    </Box>
                )}

                <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                    <Button size="l" variant="ghost" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button
                        size="l"
                        variant="primary"
                        onClick={onSave}
                        isLoading={isSaving}
                        disabled={
                            !selectedExternalAccountId ||
                            !selectedDatabase.id ||
                            !selectedExternalObjectIds.size ||
                            isLoadingDataConnections ||
                            !!error
                        }
                    >
                        Continue
                    </Button>
                </ModalFooter>
            </>
        )
    }

    return (
        <Modal open={isOpen} onOpenChange={handleOnOpenChange}>
            <ModalContent>
                {showOauthConfirmation ? (
                    <AirtableOauthConfirmation
                        handleConfirmed={() => {
                            setShowOauthConfirmation(false)
                            oauthConfirmedCallback.current?.()
                        }}
                        handleClose={handleClose}
                    />
                ) : (
                    <DataConnectionDetailModalContent />
                )}
            </ModalContent>
        </Modal>
    )
}
