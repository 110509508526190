import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon, IconName } from 'ui/components/Icon/Icon'
import { ColorSchemeLowerCase } from 'ui/styling/baseVariables/colors/colorPalette'

type ToolAvatarConfig = {
    icon: IconName
    color: ColorSchemeLowerCase
}

const TOOL_AVATARS: Record<string, ToolAvatarConfig> = {
    create_task: {
        icon: 'ClipboardPlus',
        color: 'sky',
    },
    make_api_request: {
        icon: 'Globe',
        color: 'purple',
    },
    send_email: {
        icon: 'Mail',
        color: 'emerald',
    },
    lookup_user: {
        icon: 'Search',
        color: 'pink',
    },
    // Add more tool configurations as needed
}

const DEFAULT_AVATAR: ToolAvatarConfig = {
    icon: 'Wrench',
    color: 'sky',
}

export function ToolAvatar({ toolId }: { toolId: string }) {
    const config = TOOL_AVATARS[toolId] || DEFAULT_AVATAR

    const color = config.color + '500'

    return (
        <Box
            flex
            center
            // @ts-ignore
            background={color}
            borderRadius="m"
            height="4xl"
            width="4xl"
            color="lightAlpha900"
            justifyContent="center"
            noShrink
        >
            <Icon name={config.icon} size="xl" />
        </Box>
    )
}
