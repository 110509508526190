import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { IconPickerDropdown } from 'ui/components/IconPicker/IconPickerDropdown'
import { Input } from 'ui/components/Input'
import { Toggle } from 'ui/components/Toggle'

export function EditAgentForm({ showAllOptions = false }: { showAllOptions?: boolean }) {
    const { register, setValue, watch } = useFormContext<Partial<Agent>>()
    const icon = watch('icon') || { type: 'lucide', name: 'Bot' }

    return (
        <Box flex column gap="m">
            <Field label="Agent Title" width="5xl" shrink>
                <Box flex gap="m">
                    <IconPickerDropdown
                        value={icon}
                        onChange={(newIcon) => setValue('icon', newIcon)}
                    />
                    <Input
                        grow
                        placeholder="Enter agent title"
                        {...register('title', { required: true })}
                    />
                </Box>
            </Field>

            {showAllOptions && (
                <Field label="Run operations asynchronously" width="5xl" shrink>
                    <Toggle
                        onCheckedChange={(checked) =>
                            setValue('options.async_jobs_enabled', checked)
                        }
                        checked={watch('options.async_jobs_enabled')}
                    />
                </Field>
            )}
        </Box>
    )
}
