import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useCreateAgent, useUpdateAgent } from 'data/hooks/agents/agents'
import { Agent } from 'data/hooks/agents/types'

type EditAgentInstructionsFormWrapperProps = {
    agent?: Agent
    onSuccess?: () => void
    setError?: (error: string) => void
    children: React.ReactNode
}

export function EditAgentInstructionsFormWrapper({
    agent,
    onSuccess,
    setError,
    children,
}: EditAgentInstructionsFormWrapperProps) {
    const createAgent = useCreateAgent()
    const updateAgent = useUpdateAgent()

    const methods = useForm<Partial<Agent>>({
        defaultValues: agent || {
            title: '',
            custom_instructions: '',
        },
    })

    const handleSubmit = async (data: Partial<Agent>) => {
        try {
            setError?.('')
            if (agent?._sid) {
                await updateAgent.mutateAsync({
                    id: agent._sid,
                    patch: { ...data },
                })
            } else {
                await createAgent.mutateAsync(data)
            }
            methods.reset(data)
            onSuccess?.()
        } catch (error) {
            console.error('Failed to save agent:', error)
            setError?.('Failed to save agent')
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>{children}</form>
        </FormProvider>
    )
}
