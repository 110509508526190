import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useDndContext } from '@dnd-kit/core'
import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { v4 as uuid } from 'uuid'
import * as Y from 'yjs'

import ObjectFieldsFilter from 'features/records/components/RecordFilters'
import { toYType } from 'features/utils/useYjsState'
import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'
import {
    WidgetAdminControlsComponent,
    WidgetAdminControlsProps,
    WidgetComponent,
} from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { OrderableListSelector } from 'v2/ui'
import { Item } from 'v2/ui/components/OrderableListSelector/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { IconPickerDropdown, IconValue } from 'ui/components/IconPicker/IconPickerDropdown'
import { Input } from 'ui/components/Input'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import { useTabsWidgetState } from './hooks/useTabsWidgetState'
import { useTabsWidgetTabs } from './hooks/useTabsWidgetTabs'
import { TabsWidgetTab, TabsWidgetType } from './tabsWidgetTypes'

import { TabsTriggerButtonStyles, TabsWidgetTabsListStyle } from './TabsWidget.css'

type TabsWidgetProps = {}

export const TabsWidget: WidgetComponent<TabsWidgetType, TabsWidgetProps> = ({
    widget,
    isEditing,
}) => {
    const {
        visibleTabs,
        selectedTabId,
        setSelectedTabId,
        wrapperRef,
        overflowMaxTabsLength,
        shouldOverflow,
    } = useTabsWidgetState(widget)

    if (!visibleTabs.length && !isEditing) return null

    if (!visibleTabs.length && isEditing) {
        return (
            <Box flex column gap="m" p="l">
                <Body color="textWeakest">No tabs to display</Body>
            </Box>
        )
    }

    return (
        <Tabs
            ref={wrapperRef}
            width="full"
            py="l"
            type="underlined"
            value={selectedTabId}
            onValueChange={setSelectedTabId}
        >
            <TabsList
                width="full"
                overflowMaxCount={overflowMaxTabsLength}
                overflow={shouldOverflow}
                className={TabsWidgetTabsListStyle}
            >
                {visibleTabs.map((tab) => (
                    <TabsTriggerButton
                        key={tab.id}
                        tab={tab}
                        setSelectedTabId={setSelectedTabId}
                        value={tab.id}
                        label={tab.title}
                        startIcon={tab.icon}
                    />
                ))}
            </TabsList>
            {visibleTabs.map((tab) => (
                <TabsContent key={tab.id} value={tab.id} pt="l">
                    <LayoutEditorWidgetArea key={tab.id} id={tab.id} />
                </TabsContent>
            ))}
        </Tabs>
    )
}

type TabsTriggerButtonProps = React.ComponentPropsWithoutRef<typeof TabsTrigger> & {
    tab: TabsWidgetTab
    setSelectedTabId: (id: string) => void
}

const TabsTriggerButton = React.forwardRef<HTMLButtonElement, TabsTriggerButtonProps>(
    ({ tab, setSelectedTabId, ...props }, ref) => {
        const { active } = useDndContext()

        const isDragging = !!active

        const localRef = useRef<HTMLButtonElement>(null)
        const composedRef = useComposedRefs<HTMLButtonElement>(ref, localRef)

        return (
            <Tooltip content={tab.title} side="bottom" align="center" sideOffset={-2}>
                <TabsTrigger
                    {...props}
                    ref={composedRef}
                    onMouseEnter={() => {
                        if (isDragging) setSelectedTabId(tab.id)
                    }}
                    className={TabsTriggerButtonStyles.styleFunction({
                        isOver: false,
                        hasIcon: !!tab.icon,
                    })}
                />
            </Tooltip>
        )
    }
)

type TabsWidgetAdminControlsProps = {}

export const TabsWidgetAdminControls: WidgetAdminControlsComponent<
    TabsWidgetType,
    TabsWidgetAdminControlsProps
> = ({ widget, onChange, openDetailPane }) => {
    const { allTabs, activeTabs } = useTabsWidgetTabs(widget)
    const allTabsRef = useRef(allTabs)
    allTabsRef.current = allTabs

    const onAddNew = useCallback(() => {
        onChange((attrs) => {
            const newId = `tab_${uuid()}`
            const newTitle = `Tab ${allTabsRef.current.length + 1}`

            const addedYItem = toYType({
                id: newId,
                title: newTitle,
                conditions: [],
                isActive: true,
            } as TabsWidgetTab)

            const tabs = attrs.get('tabs')
            if (tabs) {
                tabs.push([addedYItem])
            } else {
                attrs.set('tabs', Y.Array.from([addedYItem]))
            }
        })
    }, [onChange])

    const onInsert = useCallback(
        (item: Item) => {
            onChange((attrs) => {
                const addedItem = allTabsRef.current.find((tab) => tab.id === item.id)
                if (!addedItem) return
                const addedYItem = toYType({ ...addedItem, isActive: true })

                const tabs = attrs.get('tabs')
                if (tabs) {
                    tabs.push([addedYItem])
                } else {
                    attrs.set('tabs', Y.Array.from([addedYItem]))
                }
            })
        },
        [onChange]
    )

    const onUpdate = useCallback(
        (items: Item[]) => {
            const existingTabs = allTabsRef.current

            const activeItems = items.reduce((acc, item, idx) => {
                return acc.set(item.id, { ...item, idx })
            }, new Map<string, Item & { idx: number }>())

            onChange((attrs) => {
                const newTabs = existingTabs.map((tab) => {
                    const activeItem = activeItems.get(tab.id)

                    return {
                        ...tab,
                        isActive: !!activeItem,
                        idx: activeItem?.idx ?? -1,
                    }
                })
                newTabs.sort((a, b) => a.idx - b.idx)

                attrs.set('tabs', toYType(newTabs))
            })
        },
        [onChange]
    )

    const onEditName = useCallback(
        (tabId: string) => {
            openDetailPane({
                component: (props) => <TabDetailsControl {...props} tabId={tabId} />,
                label: 'Tab: Details',
            })
        },
        [openDetailPane]
    )

    const [showConditionsModalTabId, setShowConditionsModalTabId] = useState('')

    const onEditConditions = useCallback(
        (tabId: string) => {
            setShowConditionsModalTabId(tabId)
        },
        [setShowConditionsModalTabId]
    )

    const onOpenChangeConditionsModal = useCallback(
        (open: boolean) => {
            if (!open) {
                setShowConditionsModalTabId('')
            }
        },
        [setShowConditionsModalTabId]
    )

    return (
        <Box px="l" flex column gap="l" height="full" overflowY="auto">
            <OrderableListSelector
                onAdd={onInsert}
                items={allTabs.map(({ id, title }) => ({
                    name: title,
                    label: title,
                    id,
                }))}
                selectedItems={activeTabs.map(({ id, title, conditions }) => ({
                    name: title,
                    label: title,
                    id,
                    conditions,
                    actions: [
                        {
                            icon: 'edit',
                            onClick: () => onEditName(id),
                            color: 'gray.300',
                            title: 'Edit tab name',
                        },
                        {
                            icon: 'eye',
                            onClick: () => onEditConditions(id),
                            color: !!conditions?.length ? 'adminBrand' : 'gray.300',
                            title: 'Edit conditional visibility',
                        },
                    ],
                }))}
                onUpdate={onUpdate}
                mainActionButton={{
                    children: 'Add',
                    startIcon: {
                        name: 'Plus',
                    },
                    onClick: onAddNew,
                }}
            />
            <TabConditionsModal
                widget={widget}
                tabId={showConditionsModalTabId}
                onOpenChange={onOpenChangeConditionsModal}
                onChange={onChange}
            />
        </Box>
    )
}

type TabDetailsControlProps = {
    tabId: string
}

const TabDetailsControl: WidgetAdminControlsComponent<TabsWidgetType, TabDetailsControlProps> = ({
    widget,
    onChange,
    tabId,
}) => {
    const { allTabs } = useTabsWidgetTabs(widget)
    const tab = allTabs.find((tab) => tab.id === tabId)

    const icon = tab?.icon ?? undefined
    const onChangeIcon = useCallback(
        (value?: IconValue) => {
            onChange((attrs) => {
                const tabs = attrs.get('tabs')
                if (!tabs) return

                for (const tab of tabs) {
                    if (tab.get('id') !== tabId) continue

                    if (!!value) {
                        tab.set('icon', toYType(value as TabsWidgetTab['icon']))
                    } else {
                        tab.set('icon', undefined)
                    }
                    break
                }
            })
        },
        [onChange, tabId]
    )

    const title = tab?.title ?? ''
    const onChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value || ''

            onChange((attrs) => {
                const tabs = attrs.get('tabs')
                if (!tabs) return

                for (const tab of tabs) {
                    if (tab.get('id') !== tabId) continue

                    tab.set('title', value)
                    break
                }
            })
        },
        [onChange, tabId]
    )

    return (
        <Box px="l" height="full" overflowY="auto">
            <Box flex center gap="m">
                <Box noShrink>
                    <Field label="Icon">
                        <IconPickerDropdown value={icon} onChange={onChangeIcon} />
                    </Field>
                </Box>
                <Box grow>
                    <Input
                        label="Title"
                        name="title"
                        size="m"
                        autoFocus
                        value={title}
                        onChange={onChangeTitle}
                    />
                </Box>
            </Box>
        </Box>
    )
}

type TabConditionsModalProps = {
    tabId: string
    widget: WidgetAdminControlsProps<TabsWidgetType>['widget']
    onChange: WidgetAdminControlsProps<TabsWidgetType>['onChange']
    onOpenChange: (open: boolean) => void
}

const TabConditionsModal: React.FC<TabConditionsModalProps> = ({
    widget,
    onChange,
    tabId,
    onOpenChange,
}) => {
    const { object, fields } = useLayoutEditorContext()

    const { allTabs } = useTabsWidgetTabs(widget)
    const tab = allTabs.find((tab) => tab.id === tabId)

    const tabConditions = tab?.conditions ?? []
    const [conditions, setConditions] = useState(tabConditions)
    const conditionsRef = useRef(conditions)
    conditionsRef.current = conditions

    const isOpen = !!tab && !!object

    const onSave = useCallback(() => {
        const conditions = conditionsRef.current

        onChange((attrs) => {
            onOpenChange(false)

            const tabs = attrs.get('tabs')
            if (!tabs) return

            for (const tab of tabs) {
                if (tab.get('id') !== tabId) continue

                tab.set('conditions', toYType(conditions as TabsWidgetTab['conditions']))
                break
            }
        })
    }, [onChange, tabId, onOpenChange])

    useEffect(() => {
        if (isOpen) {
            // Prevent the modal from blocking pointer events on the body, so our nested dropdowns and modals still work.
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = ''
            }, 0)

            return () => clearTimeout(timer)
        } else {
            document.body.style.pointerEvents = 'auto'
        }
    }, [isOpen])

    const onInteractOutside = useCallback((e: Event) => {
        const el = e.target as HTMLElement | null
        if (!el) return

        if (el.closest('.ag-custom-component-popup')) {
            e.preventDefault()
        }
    }, [])

    if (!isOpen) return null

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent
                style={{ width: '700px', maxWidth: '100%' }}
                onInteractOutside={onInteractOutside}
                zIndex={1500}
            >
                <ModalHeader
                    title="Conditions"
                    subtitle="Select the conditions under which this tab should be visible"
                />
                <ModalContentInner>
                    <ObjectFieldsFilter
                        object={object}
                        value={tab.conditions}
                        showRoleFilter
                        fields={fields}
                        onChange={setConditions}
                        getShouldShowField={undefined}
                        hideCurrentUserOption={undefined}
                        hideTheRecordFilter={undefined}
                        showRelativeDateFilters={undefined}
                    />
                </ModalContentInner>
                <ModalFooter>
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button variant="ghost" size="l">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button size="l" onClick={onSave}>
                            Save
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
