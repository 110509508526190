import React from 'react'

import { useAgentSkills, useDeleteAgentSkill } from 'data/hooks/agents/skills'
import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body, Headline } from 'ui/components/Text'

import { EditSkillModal } from './EditSkillModal'
import { SkillListItem } from './SkillListItem'

type EditAgentSkillsProps = {
    agent: Agent
}

export function EditAgentSkills({ agent }: EditAgentSkillsProps) {
    const { data: skills = [] } = useAgentSkills(agent._sid)
    const { mutateAsync: deleteSkill } = useDeleteAgentSkill(agent._sid)

    return (
        <Box flex column grow gap="2xl">
            <Box flex justifyContent="space-between" gap="s">
                <Box flex column>
                    <Headline size="s">Skills</Headline>
                    <Body color="textWeaker" width="700px">
                        Skills are the building blocks of your agent. They are the actions that your
                        agent can perform.
                    </Body>
                </Box>
                <EditSkillModal
                    agentId={agent._sid}
                    trigger={
                        <Button
                            variant="primary"
                            flex
                            gap="m"
                            size="s"
                            center
                            startIcon={{ name: 'Plus' }}
                        >
                            Add skill
                        </Button>
                    }
                />
            </Box>
            <Box flex column grow role="list" gap="xs">
                {skills.map((skill) => (
                    <EditSkillModal
                        key={skill._sid}
                        agentId={agent._sid}
                        skill={skill}
                        trigger={
                            <SkillListItem
                                skill={skill}
                                onDelete={() => deleteSkill({ id: skill._sid })}
                            />
                        }
                    />
                ))}
                {!skills.length && (
                    <Body color="textWeaker">No skills found. Add a skill to get started.</Body>
                )}
            </Box>
        </Box>
    )
}
