import { QueryKey, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import { AgentToolConnectionDto } from './types'

// Tool Configs
const TOOL_CONNECTIONS_LIST_NAME = 'useAgentToolConnections'
const get_tools_endpoint = (agent_sid: string) => `agents/${agent_sid}/connections/`

function useToolConnectionsQueryKey(agentSid: string) {
    return useQueryKeyBuilder([TOOL_CONNECTIONS_LIST_NAME, agentSid], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type ToolConnectionsOptionsType = UseQueryOptions<
    AgentToolConnectionDto[],
    unknown,
    AgentToolConnectionDto[],
    QueryKey
>

export function useAgentToolConnections(
    agent_sid: string,
    options: ToolConnectionsOptionsType = {}
) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as ToolConnectionsOptionsType),
        keepPreviousData: false,
        ...options,
        enabled,
    }
    return useQuery<AgentToolConnectionDto[]>(
        useToolConnectionsQueryKey(agent_sid),
        get_tools_endpoint(agent_sid),
        query_config
    )
}

export function useCreateToolConnection(agent_sid: string) {
    return useCreateItem<AgentToolConnectionDto>(
        useToolConnectionsQueryKey(agent_sid),
        get_tools_endpoint(agent_sid),
        {
            onSuccess: () => {
                invalidateToolConnections()
            },
        }
    )
}

export function useUpdateToolConnection(agent_sid: string) {
    return useUpdateItem<AgentToolConnectionDto>(
        useToolConnectionsQueryKey(agent_sid),
        get_tools_endpoint(agent_sid),
        {
            onSuccess: () => {
                invalidateToolConnections()
            },
        }
    )
}

export function useDeleteToolConnection(agent_sid: string) {
    return useDeleteItem(useToolConnectionsQueryKey(agent_sid), get_tools_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateToolConnections()
        },
    })
}

export function invalidateToolConnections() {
    return queryClient.invalidateQueries([TOOL_CONNECTIONS_LIST_NAME, getCurrentStackId()])
}
