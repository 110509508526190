import React, { useState } from 'react'

import { AgentSkill } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
    ModalTrigger,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { EditSkillForm } from './EditSkillForm'
import { EditSkillFormWrapper } from './EditSkillFormWrapper'

type EditSkillModalProps = {
    agentId: string
    skill?: AgentSkill
    trigger: React.ReactNode
}

export function EditSkillModal({ agentId, skill, trigger }: EditSkillModalProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState<string>('')

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{trigger}</ModalTrigger>
            <ModalContent style={{ width: '80vw', height: '80vh' }} maxHeight="full">
                <Box fullHeight>
                    <EditSkillFormWrapper
                        agentId={agentId}
                        skill={skill}
                        onSuccess={() => {
                            setIsOpen(false)
                        }}
                        setError={setError}
                    >
                        <ModalHeader
                            title={skill ? 'Edit Skill' : 'Create Skill'}
                            showCloseButton
                        />
                        <ModalContentInner flex column fullHeight>
                            <EditSkillForm agentSid={agentId} skill={skill} />
                        </ModalContentInner>
                        <ModalFooter>
                            <ModalFooterButtonGroup>
                                <Button type="submit" size="l" variant="primary">
                                    {skill ? 'Save Changes' : 'Create Skill'}
                                </Button>
                                {error && <Body color="textError">{error}</Body>}
                            </ModalFooterButtonGroup>
                        </ModalFooter>
                    </EditSkillFormWrapper>
                </Box>
            </ModalContent>
        </Modal>
    )
}
