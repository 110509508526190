import React, { useCallback } from 'react'

import { MiddleTruncate } from '@re-dev/react-truncate'

import { useUrlAttributeDisplayState } from 'features/views/attributes/hooks/useUrlAttributeDisplayState'

import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Tooltip } from 'ui/components/Tooltip'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type UrlAttributeProps = {}

export const UrlAttribute: FieldsWidgetAttributeComponent<string, UrlAttributeProps> = ({
    value,
    field,
    isLoading,
    ...props
}) => {
    const isEmpty = !isLoading && !value

    const { target, to, href, label } = useUrlAttributeDisplayState({
        field: field.field,
        value,
        isLoading,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            {(!!href || !!to) && (
                <Skeleton isLoading={isLoading} width="full">
                    <Tooltip
                        asChild
                        content={href || to || label}
                        zIndex={200}
                        side="bottom"
                        align="start"
                    >
                        <Link
                            href={href}
                            to={to}
                            size="m"
                            target={target}
                            onClick={handleClick}
                            tabIndex={0}
                            width="full"
                        >
                            <MiddleTruncate end={7}>{label}</MiddleTruncate>
                        </Link>
                    </Tooltip>
                </Skeleton>
            )}
        </BaseAttribute>
    )
}
