import React from 'react'
import { useFormContext } from 'react-hook-form'

import type { SystemTool, ToolConfig, ToolInput } from 'data/hooks/agents/types'
import { SelectConnectionInput } from 'features/Agents/configuration/connections/SelectConnectionInput'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { Headline } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'

import { ToolListItem } from './ToolListItem'

export function ConfigureToolForm({
    tool,
    onSubmit,
    agentSid,
}: {
    tool: SystemTool
    onSubmit: (config: ToolConfig) => void
    agentSid: string
}) {
    const { register, getValues } = useFormContext<ToolConfig>()

    const handleSubmit = () => {
        const values = getValues()
        onSubmit({
            ...values,
            tool_id: tool.api_name,
        })
    }

    return (
        <Box flex column gap="l">
            <ToolListItem tool={tool} />
            <Field label="Name" width="5xl" shrink>
                <Input placeholder="Enter tool name" {...register('name')} />
            </Field>
            <Field label="API Name" width="5xl" shrink>
                <Input placeholder="Enter tool API name" {...register('api_name')} />
            </Field>

            <Field label="Description" width="5xl" shrink>
                <Textarea placeholder="Enter tool description" {...register('description')} />
            </Field>
            {tool.inputs.length > 0 && (
                <Box flex column gap="m">
                    <Headline size="2xs">Default Input Values</Headline>
                    {tool.inputs.map((input) => (
                        <ToolInput key={input.name} input={input} agentSid={agentSid} />
                    ))}
                </Box>
            )}
            <Button variant="primary" onClick={handleSubmit}>
                Save Configuration
            </Button>
        </Box>
    )
}

const ToolInput = ({ input, agentSid }: { input: ToolInput; agentSid: string }) => {
    const { register } = useFormContext<ToolConfig>()
    if (input.name === 'tool_connection_id') {
        return (
            <SelectConnectionInput
                name={`supplied_inputs.${input.name}`}
                label={input.display_name}
                agentSid={agentSid}
            />
        )
    }
    return (
        <Field key={input.name} label={input.display_name} shrink>
            <Input placeholder={input.description} {...register(`supplied_inputs.${input.name}`)} />
        </Field>
    )
}
