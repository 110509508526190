import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentValue } from 'features/views/attributes/types'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type AttachmentsAttributeProps = {}

export const AttachmentsAttribute: FieldsWidgetAttributeComponent<
    AttachmentValue[],
    AttachmentsAttributeProps
> = ({ value, field, isLoading, labelPlacement, ...props }) => {
    const isEmpty = !isLoading && (!value || value.length < 1)

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            labelPlacement={labelPlacement}
            isLoading={isLoading}
            alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
        >
            <AttachmentsAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                maxAttachments={2}
                size="m"
                maxWidth="full"
                minWidth={0}
                trim
            />
        </BaseAttribute>
    )
}
