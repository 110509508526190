import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Headline } from 'ui/components/Text'

import { LayoutEditorSaveButton } from './LayoutEditorSaveButton'

type LayoutEditorDetailPaneProps = {
    title: string
    onBackClick?: () => void
}

export const LayoutEditorDetailPane: React.FC<LayoutEditorDetailPaneProps> = ({
    title,
    onBackClick,
    children,
}) => {
    return (
        <Box pb="l" pt="m" alignSelf="stretch" height="full">
            <Box
                display="grid"
                style={{ gridTemplateRows: 'auto 1fr auto' }}
                alignSelf="stretch"
                height="full"
            >
                <Box flex center px="xs" mb="2xl">
                    <Box noShrink>
                        <Button
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'ArrowLeft' }}
                            aria-label="Go back"
                            onClick={onBackClick}
                        />
                    </Box>
                    <Box grow textAlign="center">
                        <Headline size="3xs">{title}</Headline>
                    </Box>
                    <Box noShrink>
                        <Button
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'ArrowLeft' }}
                            aria-label="Go back"
                            disabled
                            pointerEvents="none"
                            opacity={0}
                        />
                    </Box>
                </Box>
                {children}
                <LayoutEditorSaveButton px="l" />
            </Box>
        </Box>
    )
}
