import React from 'react'

import { DateAttributeDisplay } from 'features/views/attributes/DateAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type DateAttributeProps = {}

export const DateAttribute: FieldsWidgetAttributeComponent<string, DateAttributeProps> = ({
    value,
    field,
    isLoading,
    ...props
}) => {
    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <DateAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                size="m"
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}
