import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { LongTextValue } from './LongTextValue'
import { FieldsWidgetAttributeComponent } from './types'

const MAX_LINES = 3

type LongTextAttributeProps = {}

export const LongTextAttribute: FieldsWidgetAttributeComponent<string, LongTextAttributeProps> = ({
    value,
    field,
    isLoading,
    ...props
}) => {
    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            center={false}
        >
            <LongTextValue textValue={value} maxLines={MAX_LINES}>
                <LongTextAttributeDisplay
                    value={value}
                    field={field.field}
                    isLoading={isLoading}
                    size="m"
                />
            </LongTextValue>
        </BaseAttribute>
    )
}
