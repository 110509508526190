import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Agent, ToolConfig } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Body } from 'ui/components/Text'

import { ConfigureToolModal } from './ConfigureToolModal'
import { ToolListItem } from './ToolListItem'

export function EditTools({ agentSid }: { agentSid: string }) {
    const { watch, setValue } = useFormContext<Partial<Agent>>()
    const tools = watch('configured_tools') || []
    const addTool = (tool: ToolConfig) => {
        while (tools.find((t) => t.api_name === tool.api_name)) {
            tool.api_name = `${tool.api_name}_1`
        }
        setValue('configured_tools', [...tools, tool], { shouldDirty: true })
    }
    const updateTool = (idx: number, config: ToolConfig) => {
        const newTools = [...tools]
        newTools[idx] = config
        setValue('configured_tools', newTools, { shouldDirty: true })
    }
    const removeTool = (tool: ToolConfig) => {
        console.log('removeTool', tool)
        const newTools = tools.filter((t) => t.api_name !== tool.api_name)
        setValue('configured_tools', newTools, { shouldDirty: true })
    }
    return (
        <Field label="Configured Tools">
            <Box flex fullWidth alignItems="stretch" column gap="m" maxWidth="full">
                <Box gap="xs" flex column overflowY="auto">
                    {tools?.map((tool, idx) => (
                        <ConfigureToolModal
                            key={tool.api_name}
                            onSubmit={(config) => updateTool(idx, config)}
                            config={tool}
                            agentSid={agentSid}
                        >
                            <ToolListItem tool={tool} onDelete={() => removeTool(tool)} />
                        </ConfigureToolModal>
                    ))}
                    {!tools.length && <Body color="textWeaker">No tools configured</Body>}
                </Box>
                <ConfigureToolModal key={tools.length ?? 0} agentSid={agentSid} onSubmit={addTool}>
                    <Button
                        startIcon={{ name: 'Plus' }}
                        size="s"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Add Tool
                    </Button>
                </ConfigureToolModal>
            </Box>
        </Field>
    )
}
