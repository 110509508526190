import { useQuery, UseQueryOptions } from 'react-query'

import { buildQueryKey, queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { fetchAndReturn } from 'data/utils/utils'

const LIST_NAME = 'data-connections/webhook'

const useQueryKey = (nangoConnectionId?: string) => {
    return useQueryKeyBuilder([LIST_NAME, nangoConnectionId], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type AirtableWebhookDetails = {
    is_enabled: boolean
    are_notifications_enabled: boolean
    cursor_for_next_payload: number
    notification_url?: string
    expiration_time?: string
    last_successful_notification_time?: string
    last_notification_result?: any
    specification?: any
}

export type WebhookInfo = {
    data_connection_sid: string
    id: string
    status:
        | 'VALID'
        | 'WEBHOOK_NOT_SET'
        | 'WEBHOOK_NO_LONGER_EXISTS'
        | 'WEBHOOK_DISABLED'
        | 'WEBHOOK_NOTIFICATIONS_DISABLED'
    details?: AirtableWebhookDetails
}

export type WebhooksInfo = WebhookInfo[]

export const useGetWebhooks = (
    nangoConnectionId?: string,
    integrationId?: ExternalIntegrationId,
    options: UseQueryOptions<WebhooksInfo> = {}
) => {
    const queryKey = useQueryKey(nangoConnectionId)

    return useQuery<WebhooksInfo>(
        queryKey,
        async () => {
            return fetchAndReturn(
                `data-connections/webhooks/?nango_connection_id=${nangoConnectionId}&integration_id=${integrationId}`
            ) as Promise<WebhooksInfo>
        },
        {
            enabled: !!nangoConnectionId && !!integrationId,
            ...options,
        }
    )
}

export const invalidateGetWebhooks = (nangoConnectionId?: string) => {
    if (nangoConnectionId) {
        const queryKey = buildQueryKey(nangoConnectionId, {
            includeAuthKeys: true,
            includeStackId: true,
        })
        return queryClient.invalidateQueries(queryKey)
    }
    return queryClient.invalidateQueries([LIST_NAME])
}

export const fetchWebhooksForNangoConnectionId = async ({
    nangoConnectionId,
    integrationId,
}: {
    nangoConnectionId: string
    integrationId: string
}): Promise<WebhooksInfo> => {
    return (
        await fetchWithAuth(
            `data-connections/webhooks/?nango_connection_id=${nangoConnectionId}&integration_id=${integrationId}`,
            { method: 'GET' }
        )
    ).json()
}
