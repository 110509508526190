import { IconValue } from 'ui/components/IconPicker/IconPickerDropdown'

export type Agent = {
    _sid: string
    // Basic Information
    title: string
    icon?: IconValue
    system_prompt?: string
    custom_instructions?: string

    available_skills: string[]
    configured_tools: ToolConfig[]

    // Reference Fields
    account_id: string // Foreign key to Account
    stack_id: string // Foreign key to Stack

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string

    options: {
        async_jobs_enabled?: boolean
    }
}

export enum JobStatus {
    NOT_STARTED = 'not_started',
    RUNNING = 'running',
    COMPLETED = 'completed',
    FAILED = 'failed',
    WAITING_FOR_CALLBACK = 'waiting_for_callback',
    WAITING_FOR_USER_INPUT = 'waiting_for_user_input',
}

export enum JobStepStatus {
    NOT_STARTED = 'not_started',
    COMPLETED = 'completed',
    FAILED = 'failed',
    WAITING = 'waiting',
}

export type AgentJobStep = {
    tool_to_use: string
    instructions: string
    status: JobStepStatus
    result?: any // JSONField maps to any in TypeScript
}

export type AgentJob = {
    _sid: string

    // Basic Information
    title: string
    summary: string
    status: JobStatus
    steps?: AgentJobStep[]
    result?: any // JSONField maps to any in TypeScript

    // Reference Fields
    agent_id: string // Foreign key to Agent

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
}

export type MessageRole = 'user' | 'assistant' | 'system' | 'tool'

export type AgentConversation = {
    _sid: string
    title?: string

    // Reference Fields
    user_sid: string // Foreign key to User
    agent_sid: string // Foreign key to Agent

    // System Fields
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
    is_deleted: boolean
}

export type ToolCallResult = {
    tool_call_id: string
    result: any
    status: 'ok' | 'failed' | 'cancelled'
    log: (string | object)[]
    tool_call_results: ToolCallResult[]
}

export type SkillInput = {
    name: string
    description: string
}

export type AgentSkill = {
    _sid: string
    api_name: string

    // Basic Information
    name: string
    description?: string
    prompt: string
    inputs?: SkillInput[]
    available_tools: string[]
    is_system_skill: boolean

    // Reference Fields
    agent_id: string
    available_skills: string[]
    configured_tools: ToolConfig[]

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
}

export type ToolInput = {
    name: string
    display_name: string
    description?: string
}

export type SystemTool = {
    name: string
    api_name: string
    description: string
    inputs: ToolInput[]
}

export type ToolConfig = {
    tool_id: string
    name: string
    api_name: string
    description?: string
    supplied_inputs?: Record<string, string>
}

export type AgentIntegrationsDto = {
    integration_id: string
    integration_provider: string
    name: string
}

export type AgentToolConnectionDto = {
    _sid: string
    agent_sid: string
    integration_id: string
    nango_connection_id?: string
    config_data: Record<string, any>
    description: string
    created_date: string
    modified_date: string
    is_deleted: boolean
}
