import React, { useState } from 'react'

import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { IconName } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'

import { EditAgentConnections } from './connections/EditAgentConnections'
import { EditAgentGeneral } from './general/EditAgentGeneral'
import { EditAgentInstructions } from './instructions/EditAgentInstructions'
import { EditAgentSkills } from './skills/EditAgentSkills'

type SettingsTab = 'instructions' | 'skills' | 'data' | 'tools' | 'connections' | 'settings'

type MenuGroup = {
    label: string
    items: Array<{ id: SettingsTab; label: string; icon: IconName }>
}

type AgentSettingsProps = {
    agent: Agent
}

export const AgentSettings: React.FC<AgentSettingsProps> = ({ agent }) => {
    const [currentTab, setCurrentTab] = useState<SettingsTab>('instructions')

    const menuGroups: MenuGroup[] = [
        {
            label: 'Instructions',
            items: [
                { id: 'instructions', label: 'Core Instructions', icon: 'FileText' },
                { id: 'skills', label: 'Skills', icon: 'Zap' },
            ],
        },
        {
            label: 'Resources',
            items: [
                { id: 'data', label: 'Data Access', icon: 'Database' },
                { id: 'tools', label: 'Tools', icon: 'AArrowDown' },
                { id: 'connections', label: 'Connections', icon: 'Link' },
            ],
        },
        {
            label: 'General',
            items: [{ id: 'settings', label: 'Agent Settings', icon: 'Settings' }],
        },
    ]

    return (
        <Box flex height="full" width="full">
            {/* Sidebar */}
            <Box
                style={{ width: '250px', flexShrink: 0 }}
                background="surface"
                borderRightWidth="base"
                borderStyle="base"
                borderColor="borderWeak"
                flex
                column
                p="l"
                pl="3xl"
            >
                {menuGroups.map((group) => (
                    <Box key={group.label} flex column mb="l">
                        <Body flex color="textWeaker" pl="m" weight="bold" size="m" mb="s">
                            {group.label}
                        </Body>
                        {group.items.map((item) => (
                            <Button
                                size="s"
                                key={item.id}
                                startIcon={{ name: item.icon }}
                                variant={currentTab === item.id ? 'activated' : 'ghost'}
                                justifyContent="flex-start"
                                width="full"
                                onClick={() => setCurrentTab(item.id)}
                                style={{ justifyContent: 'flex-start' }}
                            >
                                {item.label}
                            </Button>
                        ))}
                    </Box>
                ))}
            </Box>

            {/* Content Area */}
            <Box flex grow pr="7xl" p="xl" shrink>
                {currentTab === 'instructions' && <EditAgentInstructions agent={agent} />}
                {currentTab === 'skills' && <EditAgentSkills agent={agent} />}
                {currentTab === 'data' && <Body>Data Access content</Body>}
                {currentTab === 'tools' && <Body>Tools content</Body>}
                {currentTab === 'connections' && <EditAgentConnections agent={agent} />}
                {currentTab === 'settings' && <EditAgentGeneral agent={agent} />}
            </Box>
        </Box>
    )
}
