import React, { useEffect, useState } from 'react'

import { useSystemTools } from 'data/hooks/agents/skills'
import { SystemTool, ToolConfig } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalHeader,
    ModalPortal,
    ModalTrigger,
} from 'ui/components/Modal'

import { ConfigureToolForm } from './ConfigureToolForm'
import { ConfigureToolFormWrapper } from './ConfigureToolFormWrapper'
import { ToolListItem } from './ToolListItem'

type ConfigureToolModalProps = {
    children: React.ReactNode
    config?: ToolConfig
    onSubmit: (config: ToolConfig) => void
    agentSid: string
}

export function ConfigureToolModal({
    children,
    config,
    onSubmit,
    agentSid,
}: ConfigureToolModalProps) {
    const { data: systemTools = [] } = useSystemTools()
    const [screen, setScreen] = useState<'select' | 'configure'>(config ? 'configure' : 'select')
    const [selectedTool, setSelectedTool] = useState<SystemTool | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleToolSelect = (tool: SystemTool) => {
        setSelectedTool(tool)
        setScreen('configure')
    }

    const handleSubmit = (toolConnection: ToolConfig) => {
        onSubmit(toolConnection)
        setIsOpen(false)
    }

    useEffect(() => {
        if (config) {
            setSelectedTool(systemTools.find((tool) => tool.api_name === config.tool_id) || null)
        }
    }, [config, systemTools])

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{children}</ModalTrigger>
            <ModalPortal>
                <ModalContent>
                    <ModalHeader
                        title={screen === 'select' ? 'Select Tool' : 'Configure Tool'}
                        showBackButton={screen === 'configure'}
                        onBackButtonClick={() => setScreen('select')}
                    />
                    <ModalContentInner>
                        {screen === 'select' && (
                            <Box flex column gap="m" pb="l">
                                <Box display="flex" column gap="m">
                                    {systemTools.map((tool) => (
                                        <ToolListItem
                                            key={tool.api_name}
                                            tool={tool}
                                            onClick={() => handleToolSelect(tool)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {screen === 'configure' && selectedTool && (
                            <ConfigureToolFormWrapper tool={selectedTool} config={config}>
                                <ConfigureToolForm
                                    agentSid={agentSid}
                                    tool={selectedTool}
                                    onSubmit={handleSubmit}
                                />
                            </ConfigureToolFormWrapper>
                        )}
                    </ModalContentInner>
                </ModalContent>
            </ModalPortal>
        </Modal>
    )
}
