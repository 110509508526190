import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'

import { AttachmentsAttribute } from './AttachmentsAttribute'
import { BaseAttribute } from './BaseAttribute'
import { CheckboxAttribute } from './CheckboxAttribute'
import { CurrencyAttribute } from './CurrencyAttribute'
import { DateAttribute } from './DateAttribute'
import { DropdownAttribute } from './DropdownAttribute'
import { LongTextAttribute } from './LongTextAttribute'
import { NumberAttribute } from './NumberAttribute'
import { PercentageAttribute } from './PercentageAttribute'
import { RecordLinksAttribute } from './RecordLinksAttribute'
import { RichTextAttribute } from './RichTextAttribute'
import { TextAttribute } from './TextAttribute'
import { FieldsWidgetAttributeComponent } from './types'
import { UrlAttribute } from './UrlAttribute'
import { UserAttribute } from './UserAttribute'

export const Attribute: FieldsWidgetAttributeComponent<any> = (props) => {
    switch (props.field.field.type) {
        case 'string':
            return <TextAttribute {...props} />

        case 'long_text':
            return <LongTextAttribute {...props} />

        case 'document':
            return <RichTextAttribute {...props} />

        case 'url':
            return <UrlAttribute {...props} />

        case 'checkbox':
            return <CheckboxAttribute {...props} />

        case 'number':
            return <NumberAttribute {...props} />

        case 'currency':
            return <CurrencyAttribute {...props} />

        case 'percentage':
            return <PercentageAttribute {...props} />

        case 'date':
        case 'datetime':
            return <DateAttribute {...props} />

        case 'user_ref':
            return <UserAttribute {...props} />

        case 'multi_file':
            return <AttachmentsAttribute {...props} />

        case 'multi_lookup':
        case 'lookup':
            return <RecordLinksAttribute {...props} />

        case 'dropdown':
        case 'multi_select':
            return <DropdownAttribute {...props} />

        default:
            return (
                <BaseAttribute {...props}>
                    <Skeleton isLoading={props.isLoading}>
                        {!!props.value && String(props.value)}
                    </Skeleton>
                </BaseAttribute>
            )
    }
}
