import React, { useEffect, useState } from 'react'

import { getUrl } from 'app/UrlService'
import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Link } from 'ui/components/Link'
import { PopoverClose } from 'ui/components/Popover'
import { Popup, PopupContent, PopupTrigger } from 'ui/components/Popup'
import { Tabs, TabsContent } from 'ui/components/Tabs'
import { Headline } from 'ui/components/Text'

import { AgentChatBox } from './AgentChatBox'
import { AgentIcon } from './AgentIcon'
import { AgentJobs } from './AgentJobs'

type AgentChatDropdownProps = {
    agent: Agent
    children: React.ReactNode
}

export function AgentChatDropdown({ agent, children }: AgentChatDropdownProps) {
    const [conversationSid, setConversationSid] = useState<string>(
        sessionStorage.getItem(`${agent._sid}-lastConversationSid`) || ''
    )

    const [chatBoxKey, setChatBoxKey] = useState<string>(Date.now().toString())

    const [tab, setTab] = useState<'chat' | 'jobs'>('chat')

    useEffect(() => {
        sessionStorage.setItem(`${agent._sid}-lastConversationSid`, conversationSid)
    }, [agent._sid, conversationSid])

    return (
        <Popup>
            <PopupTrigger asChild>{children}</PopupTrigger>
            <PopupContent px="l" flex column gap="l" zIndex={1500}>
                <Tabs
                    type="underlined"
                    value={tab}
                    // @ts-expect-error : unfortunately, the Tabs component dosen't support an union type for the value
                    onValueChange={setTab}
                    flex
                    column
                    height="full"
                    minHeight="200px"
                    maxHeight="full"
                >
                    <Box flex alignItems="stretch" pb="s">
                        <Headline size="xs" flex center gap="s" borderBottomWidth="base" pr="l">
                            <AgentIcon agent={agent} />
                            {agent.title}
                        </Headline>

                        <Box
                            p="s"
                            grow
                            justifyContent="flex-end"
                            flex
                            center
                            borderBottomWidth="base"
                        >
                            <Button
                                variant="ghost"
                                size="s"
                                iconOnly
                                title="Start new conversation"
                                startIcon={{ name: 'FilePlus2' }}
                                onClick={() => {
                                    setConversationSid('')
                                    setChatBoxKey(Date.now().toString())
                                }}
                            />
                            {/* <EditAgentModal
                                agent={agent}
                                trigger={
                                    <Button
                                        variant="ghost"
                                        size="s"
                                        iconOnly
                                        title="Edit agent"
                                        startIcon={{ name: 'Settings' }}
                                    />
                                }
                            /> */}
                            <PopoverClose asChild>
                                <Link to={getUrl(`/agents/${agent._sid}`)}>
                                    <Button
                                        variant="ghost"
                                        size="s"
                                        iconOnly
                                        startIcon={{ name: 'Expand' }}
                                        title="View agent"
                                    ></Button>
                                </Link>
                            </PopoverClose>
                        </Box>
                    </Box>
                    <TabsContent value="chat" grow height="full" flex column shrink>
                        <AgentChatBox
                            key={chatBoxKey}
                            agent={agent}
                            conversationSid={conversationSid}
                            setConversationSid={setConversationSid}
                        />
                    </TabsContent>
                    <TabsContent value="jobs" grow shrink maxHeight="full" flex column>
                        {tab === 'jobs' && <AgentJobs agent={agent} />}
                    </TabsContent>
                </Tabs>
            </PopupContent>
        </Popup>
    )
}
