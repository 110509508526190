import React from 'react'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'

import { useRecordManagerState } from './hooks/useRecordManagerState'
import { RecordManagerContext } from './useRecordManagerContext'

export type RecordManagerContextProviderProps = {
    recordSid?: string
    includeFields?: string[]
    supportUnsavedChangesModal?: boolean
}

export const RecordManagerContextProvider: React.FC<RecordManagerContextProviderProps> = ({
    recordSid,
    includeFields,
    children,
    supportUnsavedChangesModal = true,
}) => {
    const value = useRecordManagerState({
        recordSid,
        includeFields,
    })

    return (
        <RecordManagerContext.Provider value={value}>
            {children}
            {supportUnsavedChangesModal && (
                <UnsavedChangesModal
                    isDirty={value.isDirty}
                    onSave={value.saveChanges}
                    revertChanges={value.discardChanges}
                />
            )}
        </RecordManagerContext.Provider>
    )
}
