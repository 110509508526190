// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { usePrevious } from '@chakra-ui/react'

import { useAppContext } from 'app/useAppContext'
import { useDataConnection } from 'data/hooks/dataConnections/useDataConnection'

import { FieldTypeDescription } from 'v2/ui/components/FieldsEditor/FieldTypeDropdown/FieldTypeDescription'
import FieldTypeSearchableSelector from 'v2/ui/components/FieldsEditor/FieldTypeSearchableSelector/FieldTypeSearchableSelector'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { FormField } from 'ui/deprecated/forms/FormField'

import { fieldTypeComponentList, toFieldTypeComponentData } from './definitions/fieldTypeComponents'
import { FieldIconPicker } from './icons/FieldIconPicker'
import { getFieldEditionData } from './logic/availableFieldOperationUtils'

type Props = {
    field?: FieldDto | null
    object?: ObjectDto
    usePortal?: boolean
    onSubmit?: () => void
}

const FieldEditorForm: VFC<Props> = ({ field, object, usePortal, onSubmit }) => {
    const [isSelectorOpen, setIsSelectorOpen] = useState(!field)

    const { selectedStack: stack, workspaceAccount: account } = useAppContext()
    const { data: dataConnection } = useDataConnection(object?.data_connection)
    const { setValue } = useFormContext()
    const data = useWatch({})

    const fieldType = fieldTypeComponentList.find((x) => x.value === data?.selectedType)
    const ConfigurationComponent = fieldType?.configurationComponent

    const { allowedTypes: rawAllowedTypes, canChangeFieldType } =
        stack && account
            ? getFieldEditionData({
                  dataConnection: dataConnection ?? undefined,
                  field: field ?? undefined,
              })
            : { allowedTypes: [], canChangeFieldType: false }

    const allowedTypes = rawAllowedTypes.map(toFieldTypeComponentData)

    // select the short text field type if it's available
    const defaultType = allowedTypes.find((x) => x.value === 'string')?.value
    const prevFieldType = usePrevious(data.selectedType)
    const prevFieldConfigRef = useRef({})

    const resetFieldConfig = useCallback(() => {
        // Track the current field config before resetting
        if (prevFieldType) {
            prevFieldConfigRef.current[prevFieldType] = {
                connection_options: data.connection_options,
                options: data.options,
            }
        }
        // Get any previous config for this field type
        const previousFieldConfig = prevFieldConfigRef.current[data.selectedType]

        if (previousFieldConfig) {
            setValue('connection_options', previousFieldConfig.connection_options)
            setValue('options', previousFieldConfig.options)
        }
    }, [data.connection_options, data.options, data.selectedType, prevFieldType, setValue])

    useEffect(() => {
        if (prevFieldType && prevFieldType !== data.selectedType) {
            resetFieldConfig()
        }
    }, [prevFieldType, data.selectedType, resetFieldConfig])

    const effectiveField = {
        type: 'string',
        ...fieldType?.field_template,
        ...field,
        ...data,
        options: {
            ...fieldType?.field_template?.options,
            ...field?.options,
            ...data.options,
        },
    }

    return (
        <>
            <Box flex center gap="m">
                <Box noShrink>
                    <FormField
                        controlled
                        name="options.icon"
                        as={FieldIconPicker}
                        field={effectiveField}
                    />
                </Box>
                <Box grow>
                    <FormField
                        name="label"
                        as={Input}
                        size="m"
                        placeholder="Enter a field name"
                        data-testid="add-field.name"
                        autoFocus
                        disabled={!!field?.is_foreign}
                    />
                </Box>
            </Box>
            <FormField
                name="selectedType"
                as={FieldTypeSearchableSelector}
                supportedFieldTypes={allowedTypes}
                style={{ marginTop: '8px' }}
                controlled
                required
                errorMessages={{ required: 'Select a data type' }}
                placeholder="Select a data type"
                initialOpen={!field}
                disabled={!canChangeFieldType}
                usePortal={usePortal}
                onToggled={setIsSelectorOpen}
                onSubmit={onSubmit}
                controlledDefaultValue={defaultType}
            />
            {!isSelectorOpen && <FieldTypeDescription type={data.selectedType} mt={1} />}
            {!isSelectorOpen && ConfigurationComponent && !field?.is_foreign && (
                <>
                    <Box height="2xs" />
                    <ConfigurationComponent
                        dataConnection={dataConnection}
                        canChangeFieldType={canChangeFieldType}
                        field={field as FieldDto}
                        object={object}
                        previousConfigRef={prevFieldConfigRef}
                    />
                </>
            )}
        </>
    )
}

export default FieldEditorForm
