import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { TabsWidget, TabsWidgetAdminControls } from './TabsWidget'
import { TabsWidgetType } from './tabsWidgetTypes'

export const TabsWidgetMeta: WidgetSchema<TabsWidgetType> = {
    category: 'layout',
    label: 'Tabs',
    icon: 'Folders',
    component: TabsWidget,
    adminControlsComponent: TabsWidgetAdminControls,
    isContainer: true,
    defaultAttrs: {
        tabs: [],
    },
}
