import React from 'react'

import { useAgentToolConnections } from 'data/hooks/agents/tool_connections'
import { Agent, AgentToolConnectionDto } from 'data/hooks/agents/types'

import { Avatar } from 'ui/components/Avatar'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

import { ConfigureConnectionModal } from './ConfigureConnectionModal'

type EditAgentConnectionsProps = {
    agent: Agent
}

export function EditAgentConnections({ agent }: EditAgentConnectionsProps) {
    const { data: toolConnections } = useAgentToolConnections(agent._sid)

    return (
        <Box flex column grow gap="l">
            <Body>Configure the tools that this agent can use:</Body>

            {toolConnections?.map((toolConnection) => (
                <ConfigureConnectionModal
                    key={toolConnection._sid}
                    agent={agent}
                    connection={toolConnection}
                >
                    <ToolConnectionDisplay toolConnection={toolConnection} />
                </ConfigureConnectionModal>
            ))}
            <ConfigureConnectionModal agent={agent}>
                <Button variant="secondary" flex gap="m" center startIcon={{ name: 'Plus' }}>
                    Add connection
                </Button>
            </ConfigureConnectionModal>
        </Box>
    )
}

const ToolConnectionDisplay = ({
    toolConnection,
    onClick,
}: {
    toolConnection: AgentToolConnectionDto
    onClick?: () => void
}) => {
    return (
        <Box onClick={onClick} flex role="button" gap="m" center key={toolConnection._sid}>
            <Avatar
                type="icon"
                icon={{
                    name: toolConnection.integration_id === 'google-mail' ? 'Mail' : ('Cog' as any),
                }}
            />
            <Box flex column grow>
                <Body weight="medium" trim>
                    {toolConnection.description}
                </Body>
                <Body size="s" trim>
                    {toolConnection.integration_id}
                </Body>
            </Box>
        </Box>
    )
}
