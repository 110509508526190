import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'

import { useToast } from 'ui/components/Toast'

import { useDetailViewContext } from './useDetailViewContext'

type UseDeleteRecordConfirmModalStateOptions = {
    onOpenChange?: (open: boolean) => void
}

export function useDeleteRecordConfirmModalState(options: UseDeleteRecordConfirmModalStateOptions) {
    const { recordManager, object } = useDetailViewContext()!

    const record = recordManager.record
    const { onOpenChange } = options

    const [isError, setIsError] = useState(false)

    const handleOpenChange = useCallback(
        (isOpen: boolean) => {
            setIsError(false)
            onOpenChange?.(isOpen)
        },
        [onOpenChange]
    )

    const toast = useToast()
    const history = useHistory()
    const onDelete = useCallback(async () => {
        try {
            recordManager.deleteRecord()

            handleOpenChange(false)

            toast({
                title: 'Record deleted successfully',
                startIcon: { name: 'CheckCircle2' },
                type: 'success',
            })

            //TODO(axbarsan): Redirect to the parent list view.
            const newViewPath = getUrl(object?.url ?? Urls.Root)
            history.push(newViewPath)
        } catch {
            setIsError(true)
        }
    }, [handleOpenChange, history, object?.url, recordManager, toast])

    const recordName = record?._primary

    return {
        onDelete,
        isError,
        handleOpenChange,
        recordName,
    }
}
