import React, { forwardRef, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { Box } from 'ui/components/Box'
import { Icon, IconNameVariantType } from 'ui/components/Icon/Icon'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'

import * as Parts from './Tabs.parts'

import { TriggerContentStyle, TriggerCountStyle, TriggerIconStyle } from './Tabs.css'

const MAX_COUNT = 99

type IconProps = IconNameVariantType

type TabsTriggerRef = HTMLButtonElement

type TabsTriggerProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Trigger>, 'asChild'> & {
    disabled?: boolean
    count?: number
    showContent?: boolean
    startIcon?: IconProps
    label?: string
}

export const TabsTrigger = forwardRef<TabsTriggerRef, TabsTriggerProps>(
    ({ label, startIcon, showContent = true, count, value, ...props }, ref) => {
        const localRef = useRef<TabsTriggerRef>(null)
        const finalRef = useComposedRefs(ref, localRef)

        const formattedCount = formatCount(count)

        const isDisabled = props.disabled || props['aria-disabled'] === 'true'

        return (
            <Parts.Trigger {...props} value={value} ref={finalRef}>
                <Parts.TriggerInner>
                    {startIcon && (
                        <Icon
                            {...startIcon}
                            className={TriggerIconStyle}
                            size="m"
                            role="presentation"
                            aria-disabled={isDisabled || undefined}
                        />
                    )}
                    {showContent && (
                        <Box className={TriggerContentStyle} trim minWidth={0}>
                            <Body size="m" weight="medium" trim>
                                {label}
                            </Body>
                            {formattedCount && (
                                <Tag
                                    type="outline"
                                    shape="rectangle"
                                    color="Neutral"
                                    shade="light"
                                    size="2xs"
                                    disabled={isDisabled}
                                    className={TriggerCountStyle}
                                >
                                    {formattedCount}
                                </Tag>
                            )}
                        </Box>
                    )}
                </Parts.TriggerInner>
            </Parts.Trigger>
        )
    }
)

function formatCount(count?: number): string {
    if (!count) return ''
    if (count > MAX_COUNT) return `${MAX_COUNT}+`

    return count.toString()
}
