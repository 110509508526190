import React, { forwardRef, useMemo } from 'react'

import { Button, ButtonProps } from 'ui/components/Button'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { useDropdownContext } from 'ui/components/Dropdown/dropdownContext'
import { extractStandardProps } from 'ui/helpers/styles'
import { stopPropagation } from 'ui/helpers/utilities'

import * as Parts from './Tabs.parts'
import { useTabsContext } from './tabsContext'

import { ActionsDividerStyle, TabsListCard, TabsListUnderlined } from './Tabs.css'

type TabsListRef = HTMLDivElement

type TabsListProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.List>,
    'asChild' | 'overflow' | 'type' | 'hasAction'
> & {
    overflow?: boolean
    overflowMaxCount?: number
    addNewButton?: Partial<ButtonProps>
}

export const TabsList = forwardRef<TabsListRef, TabsListProps>(
    ({ children, overflow, overflowMaxCount = 5, addNewButton, ...props }, ref) => {
        const allTabs = useMemo(() => {
            return React.Children.toArray(children).filter((child) =>
                React.isValidElement(child)
            ) as React.ReactElement[]
        }, [children])

        const visibleTabs = useMemo(() => {
            if (overflow) {
                return allTabs.slice(0, overflowMaxCount)
            }

            return allTabs
        }, [allTabs, overflow, overflowMaxCount])

        const overflowingTabs = useMemo(
            () => allTabs.slice(overflowMaxCount),
            [allTabs, overflowMaxCount]
        )

        const isOverflowing = allTabs.length > visibleTabs.length

        const { type } = useTabsContext()

        const [standardProps, { className, ...rootProps }] = extractStandardProps(props)

        const hasAction = isOverflowing || !!addNewButton

        return (
            <Parts.ListWrapper {...standardProps} className={className}>
                <Parts.List
                    {...rootProps}
                    className={type === 'card' ? TabsListCard : TabsListUnderlined}
                    ref={ref}
                    hasAction={hasAction}
                >
                    {visibleTabs}
                </Parts.List>
                {hasAction && (
                    <Parts.ActionsContainer>
                        {isOverflowing && (
                            <>
                                <div role="presentation" className={ActionsDividerStyle} />
                                <ExtraTabsDropdown>{overflowingTabs}</ExtraTabsDropdown>
                            </>
                        )}
                        {addNewButton && (
                            <>
                                <div role="presentation" className={ActionsDividerStyle} />
                                <Button
                                    variant="ghost"
                                    size="s"
                                    startIcon={{
                                        name: 'Plus',
                                    }}
                                    {...addNewButton}
                                >
                                    Add new
                                </Button>
                            </>
                        )}
                    </Parts.ActionsContainer>
                )}
            </Parts.ListWrapper>
        )
    }
)

type ExtraTabsDropdownProps = {
    children: React.ReactElement[]
}

const ExtraTabsDropdown: React.FC<ExtraTabsDropdownProps> = ({ children }) => {
    return (
        <Dropdown>
            <DropdownButton
                variant="ghost"
                size="s"
                endIcon={{
                    name: 'ChevronDown',
                }}
            >
                More
            </DropdownButton>
            <ExtraTabsDropdownContent>{children}</ExtraTabsDropdownContent>
        </Dropdown>
    )
}

const ExtraTabsDropdownContent: React.FC<ExtraTabsDropdownProps> = ({ children }) => {
    const { value, setValue } = useTabsContext()

    const { setIsOpen } = useDropdownContext()!

    return (
        <DropdownContent onClick={stopPropagation}>
            {children.map((child) => {
                const isActive = child.props.value === value

                return (
                    <DropdownItem
                        key={child.key}
                        onSelect={() => {
                            setIsOpen(false)
                        }}
                        disabled={child.props.disabled}
                        label={child.props.label}
                        startIcon={child.props.startIcon}
                        multiSelect
                        checked={isActive}
                        onCheckedChange={(state) => {
                            if (state) setValue(child.props.value)
                        }}
                    />
                )
            })}
        </DropdownContent>
    )
}
