import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useFields } from 'data/hooks/fields'
import { useGetRecord } from 'data/hooks/records'
import { LoadingSpinner } from 'features/admin/data-connector/ui'
import { useProcessFilter } from 'features/records/components/RecordFilters'

import { AttributeDisplay } from 'v2/ui/components/Attribute/AttributeDisplay'

import { getFieldsWithPerms } from './getFieldsWithPerms'
import { processDynamicValue, useObjectFromId } from './helpers'

export type Props = {
    step: ActionStep
    recordId: string
    updateStepData: (stepId: string, stepData: { fields: Partial<RecordDto> }) => void
    setValid: (key: string, value: boolean) => void
    showErrors: boolean
}

const UpdateRecordStep: FC<Props> = ({ step, recordId, updateStepData, setValid, showErrors }) => {
    const requiredFields = useMemo(() => {
        const set = new Set(step.fields.map((field) => field.fieldName))
        for (const filterList of Object.values(step?.fieldConditionalVisibilityFilters ?? {})) {
            for (const filter of filterList) {
                if (filter?.field?.api_name) {
                    set.add(filter.field.api_name)
                }
            }
        }
        return [...set]
    }, [step])
    const {
        data: originalRecord,
        isLoading,
        isRefetching,
    } = useGetRecord({
        recordId,
        includeFields: requiredFields,
        useQueryOptions: {
            refetchOnMount: 'always',
        },
    })
    const hasLoadedRecordRef = useRef(false)
    const [record, setRecord] = useState<RecordDto | null>(null)
    useEffect(() => {
        // Prevent real-time updates overwriting the current values
        if (!isLoading && !isRefetching && originalRecord && !hasLoadedRecordRef.current) {
            setRecord(originalRecord)
            hasLoadedRecordRef.current = true
        }
    }, [isLoading, isRefetching, originalRecord])

    const { object } = useObjectFromId(record?._object_id ?? '')
    const { data: fields } = useFields({ objectId: object?._sid })
    const [editedRecordValues, setEditedRecordValues] = useState<Record<string, unknown>>({})

    const defaultValuesForFields = useMemo(() => {
        const defaultValues: Record<string, any> = {}
        for (const stepField of step.fields) {
            const field = fields?.find(
                (f) => f._sid === stepField.fieldId && f.object_id === object?._sid
            )
            if (field) {
                defaultValues[field.api_name] = processDynamicValue(field, stepField.value)
            }
        }

        return defaultValues
    }, [fields, object?._sid, step.fields])

    const defaultValuesForFieldsRef = useRef(defaultValuesForFields)
    defaultValuesForFieldsRef.current = defaultValuesForFields

    const processFilter = useProcessFilter()
    const fieldConditionalVisibilityFilters = step.fieldConditionalVisibilityFilters

    const fieldsWithPerms = useMemo(() => {
        if (record) {
            return getFieldsWithPerms({ ...record, ...editedRecordValues }, object, step.fields)
        }
        return []
    }, [record, editedRecordValues, object, step.fields])

    const visibleFieldsWithPerms = useMemo(() => {
        if (record) {
            return fieldsWithPerms.filter(
                (field) =>
                    !fieldConditionalVisibilityFilters?.[field.fieldId] ||
                    processFilter(
                        [{ ...record, ...editedRecordValues }],
                        fieldConditionalVisibilityFilters[field.fieldId]
                    )?.length > 0
            )
        } else {
            return []
        }
    }, [
        record,
        fieldsWithPerms,
        fieldConditionalVisibilityFilters,
        processFilter,
        editedRecordValues,
    ])

    const hasInitializedRef = useRef(false)

    useEffect(() => {
        if (record && !hasInitializedRef.current) {
            const defaultValuesForFields = defaultValuesForFieldsRef.current

            const newEditedRecordValues: Record<string, any> = {}
            for (const field of visibleFieldsWithPerms.map((f) => f.fieldName)) {
                newEditedRecordValues[field] = defaultValuesForFields[field] ?? record[field]
            }
            setEditedRecordValues(newEditedRecordValues)
            updateStepData(step.id, { fields: newEditedRecordValues })

            hasInitializedRef.current = true
        }
    }, [record, step.id, updateStepData, visibleFieldsWithPerms])

    const setValue = useCallback(
        (name, value) => {
            setEditedRecordValues((prev) => {
                const newEditedRecordValues: Record<string, any> = {
                    ...prev,
                    [name]: value,
                }

                const values: Record<string, any> = {}
                for (const field of fieldsWithPerms) {
                    const fieldValue = newEditedRecordValues[field.fieldName]
                    const fieldDef = object?.fields.find((x) => x._sid === field.fieldId)
                    values[field.fieldName] = processDynamicValue(fieldDef, fieldValue)
                }
                updateStepData(step.id, { fields: values })

                return newEditedRecordValues
            })
        },
        [fieldsWithPerms, object?.fields, step.id, updateStepData]
    )

    // When fields become visible, set the default values for the fields
    const onFieldsVisible = useCallback(
        (fieldIds: string[]) => {
            if (!record) return

            setEditedRecordValues((prev) => {
                const newValues: Record<string, any> = {}
                for (const fieldId of fieldIds) {
                    newValues[fieldId] = defaultValuesForFields[fieldId] ?? record[fieldId]
                }
                const updated = { ...prev, ...newValues }
                updateStepData(step.id, { fields: updated })
                return updated
            })
        },
        [defaultValuesForFields, record, step.id, updateStepData]
    )

    // When fields become hidden, remove the values for the fields
    const onFieldsHidden = useCallback(
        (fieldIds: string[]) => {
            setEditedRecordValues((prev) => {
                const updated = { ...prev }
                fieldIds.forEach((fieldId) => {
                    delete updated[fieldId]
                })
                updateStepData(step.id, { fields: updated })

                return updated
            })
        },
        [updateStepData, step.id]
    )

    const prevVisibleFieldsRef = useRef<Set<string>>(new Set())

    useEffect(() => {
        const currentVisibleFieldNames = new Set(visibleFieldsWithPerms.map((f) => f.fieldName))
        const prevVisibleFieldNames = prevVisibleFieldsRef.current

        const newlyVisible = [...currentVisibleFieldNames].filter(
            (x) => !prevVisibleFieldNames.has(x)
        )
        const newlyInvisible = [...prevVisibleFieldNames].filter(
            (x) => !currentVisibleFieldNames.has(x)
        )

        if (newlyVisible.length > 0) {
            onFieldsVisible(newlyVisible)
        }
        if (newlyInvisible.length > 0) {
            onFieldsHidden(newlyInvisible)
        }

        prevVisibleFieldsRef.current = currentVisibleFieldNames
    }, [visibleFieldsWithPerms, onFieldsVisible, onFieldsHidden])

    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                visibleFieldsWithPerms
                    .filter((field) => field.promptUser)
                    .map(
                        ({
                            fieldId,
                            required,
                            fullWidth,
                            readOnly,
                            label,
                            description,
                            ...otherOptions
                        }) => (
                            <AttributeDisplay
                                key={fieldId}
                                fieldId={fieldId}
                                record={editedRecordValues}
                                required={required}
                                fullWidth={fullWidth}
                                readOnly={readOnly}
                                editing
                                isVisible
                                showErrors={showErrors}
                                setValue={setValue}
                                setValid={setValid}
                                labelOverride={label}
                                editDescription={description}
                                {...otherOptions}
                            />
                        )
                    )
            )}
        </>
    )
}

export default UpdateRecordStep
