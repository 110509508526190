import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { isExternal } from 'utils/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

type UseUrlAttributeStateProps = {
    field: FieldDto
    value?: string
    openInNewTab?: boolean
    buttonTitle?: string
    maxLength?: number
    isLoading?: boolean
    showIcon?: boolean
}

export function useUrlAttributeDisplayState({
    field,
    value,
    openInNewTab,
    buttonTitle,
    maxLength,
    isLoading,
    showIcon,
}: UseUrlAttributeStateProps) {
    const target = openInNewTab ? '_blank' : undefined

    const effectiveValue = isLoading ? PLACEHOLDER_VALUE : value

    const label = formatLabel(buttonTitle, effectiveValue, maxLength)
    const overflowLabel = formatLabel(buttonTitle, effectiveValue)

    let to: string | undefined
    let href: string | undefined

    const url = formatURLForNavigation(effectiveValue)
    if (url.startsWith('/')) {
        to = url
    } else {
        href = url
    }

    const isOverflowing = maxLength && label.length > maxLength

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { to, href, target, label, isOverflowing, overflowLabel, icon }
}

/**
 * Matches the following URL prefixes:
 * - //{url}
 * - prefix:{url} (http:, https:, ftp:, mailto:, tel: etc.)
 * - /{url} for internal partial urls
 */
const urlPrefixRegExp = /^(\/\/|[a-z0-9A-Z]+:|\/)/

function formatURLForNavigation(initialUrl?: string): string {
    if (typeof initialUrl !== 'string') return ''

    let url = initialUrl.trim()

    if (!urlPrefixRegExp.test(url)) {
        url = `http://${url}`
    }

    if (!isExternal(url)) {
        const currentHostname = window.location.host
        if (url.includes(currentHostname)) {
            // Convert the URL to a relative URL, so that it can be opened without reloading the entire app.
            url = url.slice(url.indexOf(currentHostname) + currentHostname.length)
        }
    }

    url = encodeQueryParams(url)

    return url
}

function encodeQueryParams(url: string) {
    const urlParts = url.split('?')
    if (urlParts.length === 1) {
        // There are no query params in the URL.
        return url
    }
    const queryStringParts = urlParts[1].split('#', 2)
    const queryString = queryStringParts[0]

    const fragment = queryStringParts.length > 1 ? '#' + queryStringParts[1] : ''

    const queryParams = queryString.split('&')
    const encodedQueryParams = queryParams.map((param) => {
        const paramParts = param.split('=')

        let key = paramParts[0]
        try {
            key = decodeURIComponent(key)
        } catch (e) {
            console.error('Could not decode key', key, e)
        }

        let value = paramParts.length > 1 ? paramParts[1] : ''
        try {
            value = decodeURIComponent(value)
        } catch (e) {
            console.error('Could not decode value', value, e)
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })

    const encodedQueryString = encodedQueryParams.join('&')

    // Reconstruct the URL with the encoded query params.
    return `${urlParts[0]}?${encodedQueryString}${fragment}`
}

function formatLabel(buttonTitle?: string, value?: string, maxLength?: number) {
    let urlValue = value ? formatLabelUrl(value) : ''

    let label = buttonTitle || urlValue || ''

    if (maxLength) {
        label = truncateText(label, maxLength)
    }

    return label
}

const PLACEHOLDER_VALUE = 'https://stackerhq.com'

const PROTOCOL_REGEX = /^(?:https?:\/\/)?/
const TRAILING_SLASH_REGEX = /\/$/
const WWW_PREFIX_REGEX = /^www\./

function formatLabelUrl(url: string) {
    return url
        .replace(PROTOCOL_REGEX, '')
        .replace(WWW_PREFIX_REGEX, '')
        .replace(TRAILING_SLASH_REGEX, '')
}
