import React from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type CheckboxAttributeProps = {}

export const CheckboxAttribute: FieldsWidgetAttributeComponent<boolean, CheckboxAttributeProps> = ({
    value,
    field,
    isLoading,
    ...props
}) => {
    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <CheckboxAttributeDisplay value={value} field={field.field} isLoading={isLoading} />
        </BaseAttribute>
    )
}
