import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useAgentToolConnections } from 'data/hooks/agents/tool_connections'
import { AgentToolConnectionDto } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Modal, ModalContent, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

type SelectConnectionInputProps = {
    name: string
    label?: string
    agentSid: string
    preventEdit?: boolean
}

export function SelectConnectionInput({
    name,
    label = 'Connection',
    agentSid,
    preventEdit = false,
}: SelectConnectionInputProps) {
    const { setValue, watch } = useFormContext()
    const { data: availableConnections } = useAgentToolConnections(agentSid)
    const selectedConnectionId = watch(name)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleSelectConnection = (connection: AgentToolConnectionDto) => {
        setValue(name, connection._sid, { shouldDirty: true })
    }

    const selectedConnection = availableConnections?.find(
        (connection) => connection._sid === selectedConnectionId
    )

    const availableToSelect =
        availableConnections?.filter((connection) => connection._sid !== selectedConnectionId) || []

    return (
        <Field label={label}>
            <Box flex fullWidth alignItems="flex-start" column gap="m">
                <Box gap="xs" flex fullWidth column overflowY="auto">
                    {selectedConnection ? (
                        <Box
                            flex
                            role="button"
                            gap="m"
                            center
                            p="m"
                            borderWidth="base"
                            borderRadius="m"
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                if (preventEdit) {
                                    e.stopPropagation()
                                    e.preventDefault()
                                }
                            }}
                        >
                            <Box flex column grow>
                                <Body weight="medium" trim>
                                    {selectedConnection.description}
                                </Body>
                                <Body size="s" trim>
                                    {selectedConnection.integration_id}
                                </Body>
                            </Box>
                            {!preventEdit && (
                                <Button
                                    variant="ghost"
                                    size="s"
                                    startIcon={{ name: 'X' }}
                                    onClick={() => {
                                        setValue(name, undefined, { shouldDirty: true })
                                    }}
                                >
                                    Clear
                                </Button>
                            )}
                        </Box>
                    ) : (
                        !preventEdit && (
                            <Button
                                variant="secondary"
                                size="s"
                                startIcon={{ name: 'Plus' }}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Select Connection
                            </Button>
                        )
                    )}
                </Box>
            </Box>
            <Modal open={isModalOpen} onOpenChange={() => setIsModalOpen(false)}>
                <ModalContent>
                    <ModalHeader title="Select Connection" />

                    <Box gap="m" flex column pt="s" p="3xl">
                        {availableToSelect.map((connection) => (
                            <Box
                                key={connection._sid}
                                flex
                                role="button"
                                gap="m"
                                center
                                p="m"
                                borderWidth="base"
                                borderRadius="m"
                                onClick={() => {
                                    handleSelectConnection(connection)
                                    setIsModalOpen(false)
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <Box flex column grow>
                                    <Body weight="medium" trim>
                                        {connection.description}
                                    </Body>
                                    <Body size="s" trim>
                                        {connection.integration_id}
                                    </Body>
                                </Box>
                            </Box>
                        ))}
                        {!availableToSelect.length && (
                            <Body>No connections available. Please add a connection first.</Body>
                        )}
                    </Box>
                </ModalContent>
            </Modal>
        </Field>
    )
}
