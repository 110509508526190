import React, { useRef } from 'react'
import Truncate from 'react-truncate-markup'

import { useRecordLinksAttributeDisplayState } from 'features/views/attributes/hooks/useRecordLinksAttributeDisplayState'
import { Users } from 'features/views/attributes/Users'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownItemLink,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useRecordLinksAttributeState } from './hooks/useRecordLinksAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

const MAX_LINES = 3

type RecordLinksAttributeProps = {}

export const RecordLinksAttribute: FieldsWidgetAttributeComponent<
    string | string[],
    RecordLinksAttributeProps
> = ({ value, field, isLoading, labelPlacement, ...props }) => {
    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const {
        records,
        isUsersObject,
        users,
        isOverflowPopupOpen,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowPopupOpenChange,
        onLinkClick,
        attributeRef,
    } = useRecordLinksAttributeState({
        field: field,
        value,
        isLoading,
    })
    const recordsRef = useRef(records)
    recordsRef.current = records

    if (isUsersObject) {
        return (
            <BaseAttribute
                {...props}
                field={field}
                isEmpty={isEmpty}
                labelPlacement={labelPlacement}
                isLoading={isLoading}
                alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
            >
                <Users
                    value={users}
                    isLoading={isLoading}
                    size="m"
                    onClick={onLinkClick}
                    showSingleUserName={true}
                />
            </BaseAttribute>
        )
    }

    return (
        <BaseAttribute
            ref={attributeRef}
            {...props}
            field={field}
            isEmpty={isEmpty}
            labelPlacement={labelPlacement}
            isLoading={isLoading}
            alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
        >
            <Truncate
                lines={MAX_LINES}
                ellipsis={
                    <OverflowLinks
                        records={records}
                        attributeRef={attributeRef}
                        isOverflowPopupOpen={isOverflowPopupOpen}
                        onOverflowLabelCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                        onOverflowLabelMouseEnter={onOverflowLabelMouseEnter}
                        onOverflowLabelMouseLeave={onOverflowLabelMouseLeave}
                        onOverflowPopupOpenChange={onOverflowPopupOpenChange}
                        onLinkClick={onLinkClick}
                    />
                }
            >
                <Box
                    whiteSpace="normal"
                    style={{
                        wordBreak: 'break-word',
                    }}
                >
                    <Skeleton isLoading={isLoading}>
                        {records.map((record, idx) => (
                            <Truncate.Atom key={record.url}>
                                {idx > 0 && ', '}
                                <Link to={record.url} onClick={onLinkClick} size="m">
                                    {record.name}
                                </Link>
                            </Truncate.Atom>
                        ))}
                    </Skeleton>
                </Box>
            </Truncate>
        </BaseAttribute>
    )
}

type OverflowLinksProps = Pick<
    ReturnType<typeof useRecordLinksAttributeDisplayState>,
    | 'records'
    | 'isOverflowPopupOpen'
    | 'onOverflowLabelCloseAutoFocus'
    | 'onOverflowLabelMouseEnter'
    | 'onOverflowLabelMouseLeave'
    | 'onOverflowPopupOpenChange'
> & {
    attributeRef: React.RefObject<HTMLDivElement>
    onLinkClick: (e: React.MouseEvent<HTMLElement>) => void
}

const OverflowLinks: React.FC<OverflowLinksProps> = ({
    records,
    attributeRef,
    isOverflowPopupOpen,
    onOverflowLabelCloseAutoFocus,
    onOverflowLabelMouseEnter,
    onOverflowLabelMouseLeave,
    onOverflowPopupOpenChange,
    onLinkClick,
}) => {
    const links = attributeRef.current?.querySelectorAll('a') ?? []
    const overflowingRecordsLen = records.length - links.length
    const overflowingRecords = records.slice(0, overflowingRecordsLen)

    if (overflowingRecordsLen > 0) {
        return (
            <Dropdown
                open={isOverflowPopupOpen}
                onOpenChange={onOverflowPopupOpenChange}
                modal={false}
            >
                <DropdownTrigger
                    onClick={stopPropagation}
                    onMouseEnter={onOverflowLabelMouseEnter}
                    onMouseLeave={onOverflowLabelMouseLeave}
                >
                    <Body ml="s" weight="medium">
                        +{overflowingRecordsLen}
                    </Body>
                </DropdownTrigger>
                <DropdownContent
                    sideOffset={0}
                    side="bottom"
                    align="end"
                    onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                    onMouseEnter={onOverflowLabelMouseEnter}
                    onMouseLeave={onOverflowLabelMouseLeave}
                >
                    {overflowingRecords.map((record) => (
                        <DropdownItemLink
                            key={record.url}
                            to={record.url}
                            label={record.name}
                            onClick={onLinkClick}
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        )
    }

    return <>...</>
}
