import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

type UseLongTextValueStateProps = {
    textValue?: string
    maxLines?: number
}

export function useLongTextValueState({ textValue, maxLines }: UseLongTextValueStateProps) {
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [isTruncated, setIsTruncated] = useState(false)
    useLayoutEffect(() => {
        if (!maxLines) return

        const wrapper = wrapperRef.current
        if (!wrapper) return

        function checkTruncation() {
            if (!wrapper) return

            const isTruncated = wrapper.scrollHeight > wrapper.clientHeight
            setIsTruncated(isTruncated)
        }

        checkTruncation()

        const resizeObserver = new ResizeObserver(checkTruncation)
        resizeObserver.observe(wrapper)

        return () => {
            resizeObserver.disconnect()
        }
    }, [maxLines])

    const [showFullText, setShowFullText] = useState(false)

    const toggleShowFullText = useCallback(() => {
        setShowFullText((prev) => !prev)
    }, [])

    // Reset `showFullText` when value changes.
    useLayoutEffect(() => {
        setShowFullText(false)
    }, [textValue])

    return useMemo(
        () => ({
            wrapperRef,
            showFullText,
            isTruncated,
            toggleShowFullText,
        }),
        [isTruncated, showFullText, toggleShowFullText]
    )
}
