import { useMemo } from 'react'

import { CATEGORIES_ORDER, WIDGET_CATEGORIES } from 'features/views/LayoutEditor/constants'
import { LayoutEditorSchema, WidgetCategory } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type WidgetGroup = {
    id: WidgetCategory
    label: string
    widgets: WidgetGroupItem[]
}

type WidgetGroupItem = {
    label: string
    value: string
}

export function useLayoutEditorNewWidgetDropdownState() {
    const { schema } = useLayoutEditorContext()

    const groups = useDeepEqualsMemoValue(groupWidgets(schema))

    return useMemo(() => ({ groups }), [groups])
}

function groupWidgets(schema: LayoutEditorSchema): WidgetGroup[] {
    const groups = Object.entries(schema.widgets).reduce(
        (acc, [widgetType, widget]) => {
            if (widget.category !== 'hidden') {
                const group = acc[widget.category] ?? {
                    id: widget.category,
                    label: WIDGET_CATEGORIES[widget.category].label,
                    widgets: [],
                }
                group.widgets.push({ label: widget.label, value: widgetType })
                acc[widget.category] = group
            }

            return acc
        },
        {} as Record<string, WidgetGroup>
    )

    const sortedGroups = Object.values(groups).sort((a, b) => {
        return CATEGORIES_ORDER[a.id] - CATEGORIES_ORDER[b.id]
    })

    return sortedGroups
}
