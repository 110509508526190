import React from 'react'

import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'
import { RichTextValue } from 'features/views/attributes/types'

import { Skeleton } from 'ui/components/Skeleton'

import { useRichTextAttributeState } from './hooks/useRichTextAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { LongTextValue } from './LongTextValue'
import { FieldsWidgetAttributeComponent } from './types'

import { RichTextAttributeStyle } from './RichTextAttribute.css'

const MAX_LINES = 3

type RichTextAttributeProps = {}

export const RichTextAttribute: FieldsWidgetAttributeComponent<
    RichTextValue,
    RichTextAttributeProps
> = ({ value, field, isLoading, ...props }) => {
    const { isEmpty, content, recordSid, stackSid } = useRichTextAttributeState({
        isLoading,
        value,
    })

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            center={false}
        >
            <Skeleton isLoading={isLoading}>
                <LongTextValue textValue={value?.plainTextContent} maxLines={MAX_LINES}>
                    <TipTapDocumentEditor
                        content={content}
                        readOnly={true}
                        stackId={stackSid}
                        placeholder="Press '/' for commands or just start typing..."
                        width="full"
                        border={false}
                        recordId={recordSid}
                        fieldId={field?.sid}
                        allowComments={false}
                        className={RichTextAttributeStyle}
                    />
                </LongTextValue>
            </Skeleton>
        </BaseAttribute>
    )
}
