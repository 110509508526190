import React, { useCallback } from 'react'

import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'
import {
    Widget,
    WidgetAdminControlsComponent,
    WidgetComponent,
} from 'features/views/LayoutEditor/types'

import { Box } from 'ui/components/Box'
import { Input, NumberInput } from 'ui/components/Input'
import { Headline } from 'ui/components/Text'

export type SectionWidgetType = Widget<{
    title: string
    numOfColumns: number
}>

type SectionWidgetProps = {}

export const SectionWidget: WidgetComponent<SectionWidgetType, SectionWidgetProps> = ({
    widget,
}) => {
    const title = widget.attrs.title || 'Your title'
    const numOfColumns = widget.attrs.numOfColumns || 0

    return (
        <Box flex column gap="m" py="l" px={{ mobile: 0, tablet: 'l' }}>
            <Headline size="m" color="text" mb="m">
                {title}
            </Headline>
            <Box
                display="grid"
                gap="m"
                style={{
                    gridTemplateColumns: `repeat(${numOfColumns}, 1fr)`,
                }}
            >
                {new Array(numOfColumns).fill(null).map((_, idx) => (
                    <LayoutEditorWidgetArea key={idx} id={idx.toString()} />
                ))}
            </Box>
        </Box>
    )
}

type SectionWidgetAdminControlsProps = {}

export const SectionWidgetAdminControls: WidgetAdminControlsComponent<
    SectionWidgetType,
    SectionWidgetAdminControlsProps
> = ({ widget, onChange }) => {
    const title = widget.attrs.title || ''
    const numOfColumns = widget.attrs.numOfColumns

    const onChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChange((attrs) => {
                attrs.set('title', value)
            })
        },
        [onChange]
    )

    const onChangeNumOfColumns = useCallback(
        (value: number) => {
            onChange((attrs) => {
                attrs.set('numOfColumns', value)
            })
        },
        [onChange]
    )

    return (
        <Box px="l" flex column gap="l">
            <Input label="Title" placeholder="Your title" value={title} onChange={onChangeTitle} />
            <NumberInput
                label="Number of columns"
                value={numOfColumns}
                onChange={onChangeNumOfColumns}
            />
        </Box>
    )
}
