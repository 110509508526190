import React from 'react'

import { Box } from 'ui/components/Box'
import { Link } from 'ui/components/Link'
import { makeLineTruncationStyle } from 'ui/helpers/utilities'

import { useLongTextValueState } from './hooks/useLongTextValueState'

type LongTextValueProps = {
    textValue?: string
    maxLines?: number
}

export const LongTextValue: React.FC<LongTextValueProps> = ({ textValue, maxLines, children }) => {
    const { wrapperRef, isTruncated, showFullText, toggleShowFullText } = useLongTextValueState({
        textValue,
        maxLines,
    })

    return (
        <Box>
            <Box
                ref={wrapperRef}
                maxWidth="full"
                minWidth={0}
                style={{
                    ...(showFullText ? {} : makeLineTruncationStyle(maxLines)),
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                {children}
            </Box>
            {(isTruncated || showFullText) && (
                <Link onClick={toggleShowFullText}>{showFullText ? 'read less' : 'read more'}</Link>
            )}
        </Box>
    )
}
