export const TRANSLATIONS: Record<ExternalIntegrationId, Record<string, string>> = {
    airtable: {
        Database: 'Base',
        databases: 'bases',
        database: 'base',
        tables: 'tables',
        table: 'table',
    },
} as const

export const INTEGRATION_ID_TO_NAME: Record<ExternalIntegrationId, string> = {
    airtable: 'Airtable',
} as const

export const DC_TYPE_TO_INTEGRATION_NAME = {
    nango_airtable: 'Airtable',
}

export const INTEGRATION_ID_TO_DC_TYPE: Record<ExternalIntegrationId, DataConnectionType> = {
    airtable: 'nango_airtable',
} as const

export const DC_TYPE_TO_INTEGRATION_ID = Object.fromEntries(
    Object.entries(INTEGRATION_ID_TO_DC_TYPE).map(([key, value]) => [value, key])
) as Record<DataConnectionType, ExternalIntegrationId>

export const DATA_CONNECTION_DETAIL_MODAL_KEY = 'DataConnectionDetailModal'
