import React from 'react'
import { useParams } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { useAgents } from 'data/hooks/agents/agents'
import { useAgentSkills } from 'data/hooks/agents/skills'
import { AgentPageHeader } from 'features/Agents/AgentPageHeader'
import { AgentIcon } from 'features/Agents/chat/AgentIcon'
import { Frame } from 'features/core/Frame'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { LinkBase } from 'ui/components/Link/Link'
import { Headline } from 'ui/components/Text'

import { EditSkillForm } from './EditSkillForm'
import { EditSkillFormWrapper } from './EditSkillFormWrapper'

export const AgentSkillPage: React.FC = () => {
    const { agentId, skillId } = useParams<{ agentId: string; skillId: string }>()
    const { data: agents } = useAgents()
    const { data: skills = [] } = useAgentSkills(agentId)

    if (!agentId) {
        return <div>No agent ID provided</div>
    }

    const agent = agents?.find((a) => a._sid === agentId)

    if (!agent) {
        return (
            <div>
                Agent not found for {agentId}, but did find {agents?.length}{' '}
                {JSON.stringify(agents)}
            </div>
        )
    }

    const skill = skills.find((s) => s._sid === skillId)

    if (!skill) {
        return <div>Skill not found</div>
    }
    const titleSection = (
        <Box flex center gap="s">
            <LinkBase flex gap="m" to={getUrl(`${Urls.Agents}/${agentId}`)}>
                <AgentIcon color="textWeakest" agent={agent} size="xl" />
                <Headline color="textWeakest" size={{ mobile: 'xs' }}>
                    {agent.title}
                </Headline>
            </LinkBase>

            <Icon name="ChevronRight" />
            <Headline size={{ mobile: 'xs' }}>{skill.name}</Headline>
        </Box>
    )

    return (
        <Frame background="white" paddingOverride="0" hidePoweredBy>
            <Box flex column grow height="full">
                <AgentPageHeader title={agent.title} titleSection={titleSection} />
                <Box flex column height="full" width="full" p={0} center>
                    <EditSkillFormWrapper agentId={agentId} skill={skill}>
                        <EditSkillForm agentSid={agentId} skill={skill} />
                    </EditSkillFormWrapper>
                </Box>
            </Box>
        </Frame>
    )
}
