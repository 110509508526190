import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

import { EditAgentInstructionsForm } from './EditAgentInstructionsForm'
import { EditAgentInstructionsFormWrapper } from './EditAgentInstructionsFormWrapper'

const SaveInstructionsButton: React.FC<{ error?: string }> = ({ error }) => {
    const {
        formState: { isDirty },
    } = useFormContext()

    return (
        <Box mt="xl">
            <Button type="submit" size="m" variant="primary" disabled={!isDirty}>
                Save Changes
            </Button>
            {error && (
                <Body color="textError" mt="m">
                    {error}
                </Body>
            )}
        </Box>
    )
}

type EditAgentInstructionsProps = {
    agent: Agent
}

export const EditAgentInstructions: React.FC<EditAgentInstructionsProps> = ({ agent }) => {
    const [error, setError] = useState<string>('')

    return (
        <Box flex column width="full">
            <EditAgentInstructionsFormWrapper
                agent={agent}
                onSuccess={() => {
                    // Handle success if needed
                }}
                setError={setError}
            >
                <EditAgentInstructionsForm agentSid={agent._sid} />
                <SaveInstructionsButton error={error} />
            </EditAgentInstructionsFormWrapper>
        </Box>
    )
}
