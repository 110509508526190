import React, { forwardRef } from 'react'

import { Icon } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'

import * as Parts from './Field.parts'

type InfoMarkRef = HTMLDivElement

type InfoMarkProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.InfoMark>, 'isDisabled'> & {
    size?: React.ComponentPropsWithoutRef<typeof Icon>['size']
    disabled?: boolean
    zIndex?: number
}

export const InfoMark = forwardRef<InfoMarkRef, InfoMarkProps>(
    ({ children, size = 'xs', disabled, zIndex, ...props }, ref) => {
        return (
            <Parts.InfoMark ref={ref} isDisabled={disabled} {...props}>
                <Tooltip content={children} zIndex={zIndex}>
                    <Icon name="Info" size={size} aria-label="Information text" />
                </Tooltip>
            </Parts.InfoMark>
        )
    }
)
