import React from 'react'

import { DndContext, DragOverlay } from '@dnd-kit/core'

import { Box } from 'ui/components/Box'

import { LayoutEditorWidgetPickerDisplay } from './controls/LayoutEditorWidgetPickerDisplay'
import { useLayoutEditorDnDContextState } from './hooks/useLayoutEditorDnDContextState'

type LayoutEditorDnDContextProps = {}

export const LayoutEditorDnDContext: React.FC<LayoutEditorDnDContextProps> = ({ children }) => {
    const {
        sensors,
        modifiers,
        onDragStart,
        onDragOver,
        onDragEnd,
        onDragCancel,
        wrapperRef,
        selectedWidgetSchema,
        collisionDetection,
    } = useLayoutEditorDnDContextState()

    return (
        <DndContext
            sensors={sensors}
            modifiers={modifiers}
            collisionDetection={collisionDetection}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
            autoScroll
        >
            <Box ref={wrapperRef} width="full" height="full">
                {children}
            </Box>
            <DragOverlay
                dropAnimation={null}
                adjustScale={false}
                zIndex={99999}
                transition="none"
                style={{
                    width: '140px',
                    height: '75px',
                }}
                modifiers={modifiers}
            >
                {selectedWidgetSchema && (
                    <LayoutEditorWidgetPickerDisplay
                        widgetSchema={selectedWidgetSchema}
                        isDraggingOverlay={true}
                    />
                )}
            </DragOverlay>
        </DndContext>
    )
}
