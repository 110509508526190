import { useMemo } from 'react'

import { useProcessFilter } from 'features/records/components/RecordFilters'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import {
    FieldsWidgetType,
    FieldsWidgetTypeField,
} from 'features/views/LayoutEditor/widgets/FieldsWidget/fieldWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

type VisibleField = FieldsWidgetTypeField & {
    field: FieldDto
}

export function useFieldsWidgetFields(widget: FieldsWidgetType) {
    const { record } = useRecordManagerContext()
    const { fields, isEditing } = useLayoutEditorContext()

    const showAllFields = widget.attrs.showAllFields ?? true

    const widgetFields = widget.attrs.fields ?? []

    const processFilter = useProcessFilter()

    let displayedFields: VisibleField[]
    let visibleFields: VisibleField[]
    if (showAllFields) {
        const widgetFieldsApiNames = widgetFields.reduce((acc, f) => {
            return acc.set(f.apiName, f)
        }, new Map<string, FieldsWidgetTypeField>())

        displayedFields = fields.map((f) => {
            const widgetField = widgetFieldsApiNames.get(f.api_name)

            return {
                ...widgetField,
                sid: f._sid,
                field: f,
                apiName: f.api_name,
                conditions: widgetField?.conditions ?? [],
            }
        })
    } else {
        const visibleFieldApiNames = fields.reduce((acc, f) => {
            return acc.set(f.api_name, f)
        }, new Map<string, FieldDto>())

        displayedFields = widgetFields.reduce((acc, f) => {
            const field = visibleFieldApiNames.get(f.apiName)
            if (field) {
                acc.push({
                    ...f,
                    field,
                })
            }

            return acc
        }, [] as VisibleField[])
    }

    if (isEditing) {
        visibleFields = displayedFields
    } else {
        // If not editing, filter fields based on conditions.
        visibleFields = displayedFields.filter((f) => {
            if (!record && f.conditions.length) return false
            if (!record && !f.conditions.length) return true

            const filteredData = processFilter([record!], f.conditions)

            return filteredData.length > 0
        })
    }

    return useMemo(() => ({ displayedFields, visibleFields }), [displayedFields, visibleFields])
}
