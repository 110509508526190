import React, { useState } from 'react'
import Markdown from 'react-markdown'

import {
    ChatMessageBase,
    ChatUserMessageContentType,
} from 'features/AiAppBuilder/chatUtils/chatTypes'

import { useScrollIntoView } from 'v2/ui/hooks/useScrollIntoView'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { IconProps } from 'ui/components/Icon/Icon'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'

import './chatBoxStyles.css'
import './markdownStyles.css'

type ChatBoxProps<TMessage extends ChatMessageBase> = {
    onPostMessage: (message: ChatUserMessageContentType) => Promise<void>
    messages: TMessage[]
    placeholder?: string
    retryMessage?: (message: TMessage) => Promise<void>
    renderMessage?: (message: TMessage) => React.ReactNode
    renderEmptyState?: () => React.ReactNode
    aiIcon?: IconProps<'lucide'>['name']
}

export function ChatBox<TMessage extends ChatMessageBase>({
    onPostMessage,
    messages,
    placeholder = 'enter a message',
    renderEmptyState,
    retryMessage,
    renderMessage,
    aiIcon,
}: ChatBoxProps<TMessage>) {
    const [inputValue, setInputValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function handleSendMessage() {
        setIsLoading(true)
        try {
            const promise = onPostMessage(inputValue)
            setInputValue('')
            await promise
        } catch (error) {
            console.error('Error sending message:', error)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleRetryMessage(message: TMessage) {
        setIsLoading(true)
        try {
            await retryMessage?.(message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box
            id="chat-box"
            flex
            column
            alignItems="stretch"
            height="full"
            grow
            maxHeight="full"
            width="full"
            shrink
        >
            <Box overflowY="auto" grow={messages.length > 0} flex column gap="s">
                {messages.length === 0 ? (
                    renderEmptyState?.()
                ) : (
                    <>
                        {messages.map(
                            (message, index) =>
                                renderMessage?.(message) || (
                                    <ChatMessageItem
                                        key={index}
                                        message={message}
                                        aiIcon={aiIcon}
                                        retryMessage={handleRetryMessage}
                                    />
                                )
                        )}
                        {isLoading && <TypingIndicator />}
                        <ScrollIntoView key={messages.length} mt="l" />
                    </>
                )}
            </Box>
            <Box
                flex
                alignItems="center"
                background="surfaceStronger"
                borderRadius="4xl"
                px="xl"
                py="m"
                gap="m"
            >
                <Input
                    pl="m"
                    value={inputValue}
                    autoFocus
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={placeholder}
                    name="question"
                    autoComplete="off"
                    style={{
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        background: 'rgba(0,0,0,0)',
                        height: 30,
                    }}
                    variant="borderless"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage()
                        }
                    }}
                />
                <Button
                    startIcon={{ name: 'ArrowUp' }}
                    iconOnly
                    style={{ borderRadius: '100%' }}
                    onClick={handleSendMessage}
                />
            </Box>
        </Box>
    )
}

function TypingIndicator(props: BoxProps) {
    return (
        <Box className="typing-indicator" {...props}>
            <span></span>
            <span></span>
            <span></span>
        </Box>
    )
}

function ChatMessageItem<TMessage extends ChatMessageBase>({
    message,
    retryMessage,
    aiIcon,
}: {
    message: TMessage
    retryMessage?: (message: TMessage) => Promise<void>
    aiIcon?: IconProps<'lucide'>['name']
}) {
    if (!message.content || message.type === 'tool') return null
    const isHuman = message.type === 'human'

    return (
        <>
            <Box
                pt="m"
                pb="m"
                pr="l"
                pl="l"
                background={isHuman ? 'surfaceStronger' : 'surface'}
                alignSelf={isHuman ? 'flex-end' : 'flex-start'}
                className="markdown ChatMarkdown"
                borderRadius="2xl"
                position="relative"
                flex
            >
                {!isHuman && (
                    <Box
                        background="surface"
                        borderRadius="pill"
                        borderStyle="base"
                        borderWidth="base"
                        borderColor="borderWeak"
                        p="s"
                        alignItems="center"
                        flex
                        width="4xl"
                        maxHeight="4xl"
                        style={{
                            marginTop: '-6px',
                            marginRight: '12px',
                            marginLeft: '-12px',
                        }}
                    >
                        <Icon name={aiIcon || 'Bot'} size="l" />
                    </Box>
                )}
                <Body size="m" maxWidth="full">
                    <Markdown>{message.content.toString()}</Markdown>
                </Body>
            </Box>
            {'sendingFailed' in message && message.sendingFailed && (
                <Body size="xs" color="textError" alignSelf={isHuman ? 'flex-end' : 'flex-start'}>
                    Failed to send.{' '}
                    {retryMessage && (
                        <Box
                            as="span"
                            textDecoration="underline"
                            role="button"
                            onClick={(e: React.MouseEvent) => {
                                retryMessage(message)
                                e.preventDefault()
                            }}
                        >
                            Try again
                        </Box>
                    )}
                </Body>
            )}
        </>
    )
}

function ScrollIntoView(props: BoxProps) {
    const ref = useScrollIntoView()
    return <Box ref={ref} {...props} />
}
