import React from 'react'

import { DEFAULT_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'

import { Box } from 'ui/components/Box'

import { useDetailViewCompactLayoutState } from './hooks/useDetailViewCompactLayoutState'

import { DetailViewCompactLayoutSectionStyles } from './DetailViewLayout.css'

type DetailViewCompactLayoutProps = {}

export const DetailViewCompactLayout: React.FC<DetailViewCompactLayoutProps> = () => {
    const { contentWidth } = useDetailViewCompactLayoutState()

    return (
        <Box
            as="main"
            display="grid"
            height="full"
            maxWidth="full"
            minWidth={0}
            px={{
                mobile: 'xl',
                tablet: '3xl',
            }}
            overflow={{
                mobile: 'auto',
                tablet: 'visible',
            }}
        >
            <Box
                as="section"
                className={DetailViewCompactLayoutSectionStyles.styleFunction({ contentWidth })}
            >
                <LayoutEditorWidgetArea id={DEFAULT_WIDGET_AREA_ID} />
            </Box>
        </Box>
    )
}
