import { useMemo } from 'react'

import shortid from 'shortid'
import { getToday } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/timePeriodFilters/getToday'

import { useActions } from 'data/hooks/actions'
import { useObjects } from 'data/hooks/objects'
import { LINK_TYPES } from 'data/utils/fieldDefinitions'
import { getCurrentUserProfileRecordId, getCurrentUserRecordId } from 'data/wrappers/withUserUtils'
import { DATE_TYPES } from 'features/admin/fields/common'
import { ensureArray } from 'utils/utils'

import { TODAY_DEFAULT_VALUE } from 'v2/ui/components/Attribute/constants'

export function useActionsFromObjectId(objectId: string) {
    const { data: actions, status, ...other } = useActions()

    const data = useMemo(() => {
        if (status !== 'success') {
            return
        }

        return (actions as ActionDto[]).filter((action: ActionDto) => objectId === action.object_id)
    }, [actions, objectId, status])

    return { data, status, ...other }
}

export function useObjectFromId(objectId: string) {
    const { data: objects, status } = useObjects()

    return useMemo(() => {
        if (status !== 'success' || !objectId) {
            return { object: null, status }
        }

        const object = objects.find(({ _sid }) => objectId === _sid)
        return { object, status }
    }, [objectId, objects, status])
}

export function updateStep(action: ActionDto, stepId: string, patch: Partial<ActionStep>) {
    const newSteps = action.options.steps.map((step) => {
        if (step.id === stepId) {
            return { ...step, ...patch }
        }
        return step
    })

    return { options: { ...action.options, steps: newSteps } }
}

export function addStep(action: ActionDto, step: ActionStep): Pick<ActionDto, 'options'> {
    return { options: { ...action.options, steps: [...action.options.steps, step] } }
}

export function updateStepField(
    action: ActionDto,
    stepId: string,
    fieldId: string,
    patch: Partial<ActionStepField>
) {
    const steps = action.options.steps || []

    const stepToUpdate = steps.find(({ id }) => id === stepId)

    if (!stepToUpdate) {
        throw new Error(`Can't update step id ${stepId}`)
    }

    const fields = stepToUpdate.fields.map((field) => {
        if (field.fieldId === fieldId) {
            return { ...field, ...patch }
        }

        return field
    })

    return updateStep(action, stepId, { fields })
}

export function processDynamicValue(field: FieldDto, value: any) {
    let result = value
    // If this is a lookup field, and one of the values is 'current_user' then replace
    // it with the current user record id
    if (LINK_TYPES.includes(field.type) && ensureArray(value).includes('current_user')) {
        const userId = getCurrentUserRecordId()
        // If we have a user profile record for the target object,
        // then we'll use that id instead of their native stacker user cache record id
        const userProfileRecordId = getCurrentUserProfileRecordId(field.link_target_object_id ?? '')
        if (Array.isArray(value)) {
            result = result.map((v: any) =>
                v === 'current_user' ? userProfileRecordId || userId : ''
            )
        } else {
            result = userProfileRecordId || userId
        }
    }
    if (DATE_TYPES.includes(field.type) && value === TODAY_DEFAULT_VALUE) {
        const today = getToday()
        result = field.type === 'datetime' ? today.toISOString() : today.format('YYYY-MM-DD')
    }

    return result
}

export function filterWorkflowsForActionButton(
    workflows: WorkflowDto[],
    objectId: string
): WorkflowDto[] {
    return workflows.filter((workflow) => {
        if (!workflow.is_enabled) return false

        const trigger = workflow.trigger
        if (!trigger) return false
        if (trigger.settings?.object_id !== objectId) return false
        if (trigger.trigger_type !== 'action_button_clicked') return false

        return true
    })
}

export function buildStep(type: string, displayName: string): ActionStep {
    return {
        type,
        displayName,
        id: 'action_step_' + shortid.generate(),
        fields: [],
    }
}
