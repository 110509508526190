import React from 'react'

import { useJob } from 'data/hooks/agents/job'
import { AgentJob, JobStepStatus } from 'data/hooks/agents/types'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'

type Props = {
    job: AgentJob
    showStatusText?: boolean
}
export function JobListItem({ job: suppliedJob, showStatusText = true }: Props) {
    const { data: dataFromServer } = useJob(suppliedJob.agent_id, suppliedJob._sid, {
        refetchOnMount: 'always',
    })
    const jobFromServer = dataFromServer?.job
    const job = jobFromServer || suppliedJob
    async function restartJob() {
        await fetchWithAuth(`agents/${job?.agent_id}/jobs/${job?._sid}/execute`, {
            method: 'POST',
        })
    }
    let statusIcon: IconName = 'TicketPlus'
    if (job?.status === 'completed') {
        statusIcon = 'TicketCheck'
    } else if (job?.status === 'failed') {
        statusIcon = 'TicketX'
    } else if (job?.status === 'running') {
        statusIcon = 'Cog'
    } else if (job?.status === 'waiting_for_callback') {
        statusIcon = 'Hourglass'
    } else if (job?.status === 'waiting_for_user_input') {
        statusIcon = 'Flag'
    }

    let statusText = 'Created job'
    if (job?.status === 'completed') {
        statusText = 'Completed job'
    } else if (job?.status === 'failed') {
        statusText = 'Failed job'
    } else if (job?.status === 'running') {
        statusText = 'Running job'
    } else if (job?.status === 'waiting_for_callback') {
        statusText = 'Waiting'
    } else if (job?.status === 'waiting_for_user_input') {
        statusText = 'Waiting for user input'
    }

    const showRestartButton = false
    return (
        <Collapsible>
            <CollapsibleTrigger width="full">
                <Box flex center gap="s" width="full" fontSize="bodyS">
                    <Icon name={statusIcon} className={statusIcon === 'Cog' ? 'spin' : ''} />
                    {showStatusText && (
                        <Body color="textWeaker" size="s">
                            {statusText}:
                        </Body>
                    )}
                    <Body size="s">{job?.title}</Body>
                    <Box grow />
                    <Icon name="ChevronDown" color="textWeaker" />
                </Box>
            </CollapsibleTrigger>
            <CollapsibleContent flex column background="white00" p="m" rounded="m" mt="l" gap="s">
                <Body size="s" color="textWeaker" weight="bold">
                    Summary:
                </Body>
                <Body color="textWeaker" size="s">
                    {job?.summary}
                </Body>

                <Body size="s" color="textWeaker" weight="bold" mt="s">
                    Steps:
                </Body>
                <Box as="ul" flex column gap="s" ml="l">
                    {job?.steps?.map((step, idx) => (
                        <Box as="li" key={idx} flex alignItems="flex-start" gap="s">
                            <Icon
                                name={getStepIcon(step.status)}
                                display="inline"
                                noShrink
                                mt="2xs"
                            />
                            <Body size="s">{step.instructions}</Body>
                        </Box>
                    ))}
                </Box>
                {job?.result && (
                    <>
                        <Body size="s" color="textWeaker" weight="bold" mt="s">
                            Result:
                        </Body>
                        <Body background="gray100" p="s" rounded="s">
                            {job.result}
                        </Body>
                    </>
                )}
                {showRestartButton && <Button onClick={() => restartJob()}>Run again</Button>}
            </CollapsibleContent>
        </Collapsible>
    )
}

function getStepIcon(status: JobStepStatus): IconName {
    if (status === JobStepStatus.COMPLETED) return 'CheckSquare2'
    if (status === JobStepStatus.FAILED) return 'XSquare'
    if (status === JobStepStatus.WAITING) return 'Hourglass'
    return 'Square'
}
