import React, { useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import settings from 'app/settings'
import { Urls } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'
import { useDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useDeleteWebhooks } from 'data/hooks/dataConnections/useDeleteWebhook'
import {
    fetchWebhooksForNangoConnectionId,
    WebhookInfo,
} from 'data/hooks/dataConnections/useGetWebhooks'
import { useRepairWebhooks } from 'data/hooks/dataConnections/useRepairWebhooks'
import { useObjects } from 'data/hooks/objects'
import { DC_TYPE_TO_INTEGRATION_ID } from 'features/DataConnections/constants'
import { useIsCurrentUserStackerSupport } from 'utils/supportLogin'

import { Box } from 'ui/components/Box'
import { Spinner } from 'ui/components/Spinner'

export const DataConnectionSupportView = () => {
    const isCurrentUserStackerSupport = useIsCurrentUserStackerSupport()
    const { isAdmin } = useAppUserContext()
    const isEnabled = isCurrentUserStackerSupport && isAdmin
    const { data: dataConnections, isLoading: isLoadingDataConnections } = useDataConnections()
    const { data: objects, isLoading: isLoadingObjects } = useObjects()
    const { selectedStack } = useAppContext()

    const [isLoadingWebhooks, setIsLoadingWebhooks] = useState(true)
    const [webhooks, setWebhooks] = useState({} as Record<string, WebhookInfo[]>)
    const { mutateAsync: repairWebhooks } = useRepairWebhooks()
    const { mutateAsync: deleteWebhooks } = useDeleteWebhooks()

    const refetchWebhooks = useCallback(() => {
        setIsLoadingWebhooks(true)
        setWebhooks({})
        const promises: Array<Promise<unknown>> = []
        const newWebhooks: Record<string, WebhookInfo[]> = {}
        if (dataConnections) {
            for (const dc of dataConnections) {
                if (dc.nango_connection_id) {
                    promises.push(
                        fetchWebhooksForNangoConnectionId({
                            nangoConnectionId: dc.nango_connection_id,
                            integrationId: DC_TYPE_TO_INTEGRATION_ID[dc.type],
                        }).then((dcWebhooks) => {
                            newWebhooks[dc._sid] = dcWebhooks
                        })
                    )
                }
            }
        }
        Promise.all(promises)
            .then(
                () => {
                    setWebhooks(newWebhooks)
                },
                (e) => {
                    console.warn('Failed to load webhooks')
                    console.warn(e)
                }
            )
            .finally(() => {
                setIsLoadingWebhooks(false)
            })
    }, [dataConnections, setIsLoadingWebhooks])

    useEffect(() => {
        if (dataConnections?.length && !isLoadingDataConnections) {
            refetchWebhooks()
        }
    }, [dataConnections, isLoadingDataConnections, refetchWebhooks])

    const isLoading = isLoadingDataConnections || isLoadingObjects || isLoadingWebhooks

    if (!isEnabled) {
        return <Redirect to={Urls.Root} />
    }

    if (isLoading || !selectedStack || !dataConnections || !objects) {
        return <Spinner />
    }

    const objectsPerDataConnection: Record<string, ObjectDto[]> = {}
    dataConnections.forEach((dc) => (objectsPerDataConnection[dc._sid] = []))
    objects.forEach((obj) => {
        if (obj.data_connection && objectsPerDataConnection[obj.data_connection]) {
            objectsPerDataConnection[obj.data_connection].push(obj)
        }
    })

    const pStyle = { margin: '0.5rem 0' }

    return (
        <Box m="l">
            <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>
                Data connection support view
            </h1>
            {dataConnections?.map((dc: DataConnectionDto) => {
                return (
                    <Box mb="3xl" key={dc._sid}>
                        <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                            {dc.label} ({dc.type})
                        </h3>
                        <p style={pStyle}>
                            <b>Nango connection ID:</b> {dc.nango_connection_id}
                        </p>
                        <p style={pStyle}>
                            <b>External database ID:</b> {dc.external_database_id}
                        </p>
                        <p style={pStyle}>
                            <b>External object IDs:</b> {(dc.external_object_ids ?? []).join(', ')}
                        </p>
                        <p style={pStyle}>
                            <b>External user email and ID:</b> {dc.external_user_email} (
                            {dc.external_user_id})
                        </p>
                        <p style={pStyle}>
                            <b>Status:</b> {dc.status}
                        </p>
                        <p style={pStyle}>
                            <b>Last sync:</b> started at {dc.last_sync_start_time ?? '?'}, completed
                            at {dc.last_sync_completed_time ?? '?'}
                        </p>
                        <p style={pStyle}>
                            <b>Objects:</b>{' '}
                            {objectsPerDataConnection[dc._sid]?.length ? '' : 'none'}
                        </p>
                        {objectsPerDataConnection[dc._sid]?.length && (
                            <ul style={{ paddingLeft: '1rem' }}>
                                {objectsPerDataConnection[dc._sid].map((obj) => (
                                    <li key={obj._sid}>
                                        {obj.name} ({obj._sid}, {obj.external_id})
                                    </li>
                                ))}
                            </ul>
                        )}
                        <p style={pStyle}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    repairWebhooks({
                                        nangoConnectionId: dc.nango_connection_id ?? '',
                                        integrationId: DC_TYPE_TO_INTEGRATION_ID[dc.type],
                                    }).then(() => {
                                        refetchWebhooks()
                                    })
                                }}
                            >
                                Repair webhooks
                            </a>
                        </p>
                        {webhooks[dc._sid] && (
                            <>
                                <h4 style={{ ...pStyle, fontWeight: 'bold' }}>Webhooks</h4>
                                <ul style={{ paddingLeft: '1rem' }}>
                                    {webhooks[dc._sid].map((webhook) => (
                                        <li key={webhook.id}>
                                            {webhook.id}: {webhook.status} <br />
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    if (
                                                        window.confirm(
                                                            'Do you want to delete this webhook?'
                                                        )
                                                    ) {
                                                        e.preventDefault()
                                                        deleteWebhooks({
                                                            nangoConnectionId:
                                                                dc.nango_connection_id ?? '',
                                                            integrationId:
                                                                DC_TYPE_TO_INTEGRATION_ID[dc.type],
                                                            webhookId: webhook.id,
                                                            externalDatabaseId:
                                                                dc.external_database_id ?? '',
                                                        }).then(() => {
                                                            refetchWebhooks()
                                                        })
                                                    }
                                                }}
                                            >
                                                Delete
                                            </a>{' '}
                                            <br />
                                            {webhook.details &&
                                                ` (${JSON.stringify(webhook.details)})`}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        <p style={pStyle}>
                            <b>
                                <a
                                    href={`https://app.nango.dev/${settings.IS_PROD ? 'prod' : 'dev'}/logs?connections=${encodeURIComponent(dc.nango_connection_id ?? '')}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Logs in Nango
                                </a>
                            </b>
                        </p>
                    </Box>
                )
            })}
        </Box>
    )
}
