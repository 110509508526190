import React, { useEffect, useMemo, useState } from 'react'

import { Agent, AgentIntegrationsDto, AgentToolConnectionDto } from 'data/hooks/agents/types'
import { useAgentIntegrations } from 'data/hooks/agents/useAgentIntegrations'

import { Box } from 'ui/components/Box'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalHeader,
    ModalPortal,
    ModalTrigger,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { ConfigureConnectionForm } from './ConfigureConectionForm'

type ConfigureConnectionModalProps = {
    agent: Agent
    children: React.ReactNode
    connection?: AgentToolConnectionDto
}

export function ConfigureConnectionModal({
    agent,
    children,
    connection,
}: ConfigureConnectionModalProps) {
    const { data: integrationsData } = useAgentIntegrations()
    const availableIntegrations = useMemo(
        () => integrationsData?.integrations || [],
        [integrationsData]
    )

    const [screen, setScreen] = useState<'select' | 'configure'>(
        connection ? 'configure' : 'select'
    )
    const [selectedIntegration, setSelectedIntegration] = useState<AgentIntegrationsDto | null>(
        null
    )
    const [isOpen, setIsOpen] = useState(false)

    const handleIntegrationSelect = (integration: AgentIntegrationsDto) => {
        setSelectedIntegration(integration)
        setScreen('configure')
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        if (connection) {
            setSelectedIntegration(
                availableIntegrations?.find(
                    (integration) => integration.integration_id === connection.integration_id
                ) || null
            )
        }
    }, [connection, availableIntegrations])

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{children}</ModalTrigger>
            <ModalPortal>
                <ModalContent>
                    <ModalHeader
                        title={screen === 'select' ? 'Set Up Connection' : 'Configure Connection'}
                        showBackButton={screen === 'configure'}
                        onBackButtonClick={() => setScreen('select')}
                    />
                    <ModalContentInner>
                        {screen === 'select' && (
                            <Box flex column gap="m" pb="l">
                                <Box
                                    display="grid"
                                    style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}
                                    gap="m"
                                >
                                    {availableIntegrations.map((integration) => (
                                        <Box
                                            border
                                            flex
                                            column
                                            p="m"
                                            role="button"
                                            key={integration.integration_id}
                                            onClick={() => handleIntegrationSelect(integration)}
                                        >
                                            <Body weight="bold">{integration.name}</Body>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {screen === 'configure' && selectedIntegration && (
                            <ConfigureConnectionForm
                                connection={connection}
                                agent={agent}
                                integration={selectedIntegration}
                                handleClose={handleClose}
                            />
                        )}
                    </ModalContentInner>
                </ModalContent>
            </ModalPortal>
        </Modal>
    )
}
