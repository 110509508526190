import React, { useState } from 'react'

import { Agent } from 'data/hooks/agents/types'
import { EditAgentConnections } from 'features/Agents/configuration/connections/EditAgentConnections'
import { EditAgentForm } from 'features/Agents/configuration/general/EditAgentForm'
import { EditAgentFormWrapper } from 'features/Agents/configuration/general/EditAgentFormWrapper'
import { EditAgentSkills } from 'features/Agents/configuration/skills/EditAgentSkills'

import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalContent,
    ModalFooterButtonGroup,
    ModalHeader,
    ModalTabs,
    ModalTabsContent,
    ModalTrigger,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

type EditAgentModalProps = {
    agent?: Agent
    trigger: React.ReactNode
}

export function EditAgentModal({ agent, trigger }: EditAgentModalProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState<string>('')

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{trigger}</ModalTrigger>
            <ModalContent>
                <ModalHeader title={agent ? 'Edit Agent' : 'Create Agent'} showCloseButton />
                <ModalTabs defaultValue="settings" type="underlined">
                    {/* <ModalTabsList aria-label="Aria-Label">
                        <ModalTabsTrigger value="settings" label="Settings" />
                        <ModalTabsTrigger value="skills" label="Skills" />
                        <ModalTabsTrigger value="connections" label="Connections" />
                    </ModalTabsList> */}
                    <ModalTabsContent value="settings" mb="xl">
                        <EditAgentFormWrapper
                            agent={agent}
                            onSuccess={() => {
                                setIsOpen(false)
                            }}
                            setError={setError}
                        >
                            <EditAgentForm />
                            <ModalFooterButtonGroup mt="xl">
                                <Button type="submit" size="l" variant="primary" width="full">
                                    {agent ? 'Save Changes' : 'Create Agent'}
                                </Button>
                                {error && <Body color="textError">{error}</Body>}
                            </ModalFooterButtonGroup>
                        </EditAgentFormWrapper>
                    </ModalTabsContent>
                    <ModalTabsContent value="skills">
                        {agent ? <EditAgentSkills agent={agent} /> : <Body>No agent selected</Body>}
                    </ModalTabsContent>
                    <ModalTabsContent value="connections">
                        {agent ? (
                            <EditAgentConnections agent={agent} />
                        ) : (
                            <Body>No agent selected</Body>
                        )}
                    </ModalTabsContent>
                </ModalTabs>
            </ModalContent>
        </Modal>
    )
}
