import React from 'react'

import publicAsset from 'utils/publicAsset'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { ModalFooter, ModalHeader } from 'ui/components/Modal'
import { theme } from 'ui/styling/Theme.css'

type AirtableOauthConfirmationProps = {
    handleConfirmed: () => void
    handleClose: () => void
}
export const AirtableOauthConfirmation: React.FC<AirtableOauthConfirmationProps> = ({
    handleConfirmed,
    handleClose,
}) => {
    return (
        <Box>
            <Box
                flex
                justifyContent="center"
                py="3xl"
                style={{ backgroundColor: theme.color.gray50 }}
            >
                <img
                    src={publicAsset('/static/media/airtableOauthBaseSelection.png')}
                    alt="Airtable base selection"
                    width="378px"
                    height="188px"
                />
            </Box>
            <ModalHeader
                title="Connect Airtable account"
                subtitle={
                    <>
                        For the best experience, we recommend granting access to{' '}
                        <span style={{ textDecoration: 'underline' }}>all bases</span>.
                    </>
                }
                showCloseButton={true}
            />

            <Banner
                icon={{
                    name: 'Info',
                }}
                mx="3xl"
                my="xl"
                helper="Giving access to all bases helps avoid disruptions and ensures a seamless workflow across your Apps."
                type="info"
            />

            <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                <Button size="l" variant="ghost" onClick={handleClose} mr="xs">
                    Cancel
                </Button>
                <Button size="l" variant="primary" onClick={handleConfirmed}>
                    Understood, connect
                </Button>
            </ModalFooter>
        </Box>
    )
}
