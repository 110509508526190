import React from 'react'

import { Divider } from 'ui/components/Divider'

type LayoutEditorControlSeparatorProps = React.ComponentPropsWithoutRef<typeof Divider> & {}

export const LayoutEditorControlSeparator: React.FC<LayoutEditorControlSeparatorProps> = (
    props
) => {
    return <Divider variant="strong" height="xs" px="l" {...props} />
}
