import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewHeaderEyebrowAttributeState() {
    const { fields, header, recordManager } = useDetailViewContext()!

    const eyebrowFieldApiName = header.eyebrow?.fieldApiName
    const eyebrowField = fields.find((field) => field.api_name === eyebrowFieldApiName)

    let value: any = recordManager.record?.[eyebrowField?.api_name!]
    if (Array.isArray(value)) {
        value = [...value].sort()
    }

    return useMemo(
        () => ({
            field: eyebrowField,
            value,
            dereferencedRecords: recordManager.dereferencedRecords,
        }),
        [eyebrowField, value, recordManager.dereferencedRecords]
    )
}
