import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

type DeleteWebhookResponse = {
    success: boolean
}

type useDeleteWebhooksInput = {
    nangoConnectionId: string
    integrationId: ExternalIntegrationId
    webhookId: string
    externalDatabaseId: string
}
export function useDeleteWebhooks(
    options: UseMutationOptions<DeleteWebhookResponse, unknown, useDeleteWebhooksInput> = {}
) {
    return useMutation(
        async ({
            nangoConnectionId,
            integrationId,
            webhookId,
            externalDatabaseId,
        }: useDeleteWebhooksInput) => {
            return fetchAndReturn(`data-connections/webhooks/delete/`, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    nango_connection_id: nangoConnectionId,
                    integration_id: integrationId,
                    webhook_id: webhookId,
                    external_database_id: externalDatabaseId,
                }),
            }) as Promise<DeleteWebhookResponse>
        },
        options
    )
}
