import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type DropdownAttributeProps = {}

export const DropdownAttribute: FieldsWidgetAttributeComponent<
    string | string[],
    DropdownAttributeProps
> = ({ value, field, isLoading, labelPlacement, ...props }) => {
    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            labelPlacement={labelPlacement}
            isLoading={isLoading}
            alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
        >
            <DropdownAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                maxOptions={3}
                size="m"
            />
        </BaseAttribute>
    )
}
