import { QueryKey, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import { AgentSkill, SystemTool } from './types'

// Skills
const SKILLS_LIST_NAME = 'useAgentSkills'
const get_skills_endpoint = (agent_sid: string) => `agents/${agent_sid}/skills/`
const SYSTEM_SKILLS_ENDPOINT = `system-skills/`

function useSkillsQueryKey() {
    return useQueryKeyBuilder(SKILLS_LIST_NAME, {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type SkillsOptionsType = UseQueryOptions<AgentSkill[], unknown, AgentSkill[], QueryKey>

export function useAgentSkills(agent_sid: string, options: SkillsOptionsType = {}) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as SkillsOptionsType),
        keepPreviousData: true,
        ...options,
        enabled,
    }
    return useQuery<AgentSkill[]>(useSkillsQueryKey(), get_skills_endpoint(agent_sid), query_config)
}

export function useSystemTools() {
    return useQuery<SystemTool[]>('useSystemTools', SYSTEM_SKILLS_ENDPOINT)
}

export function useCreateAgentSkill(agent_sid: string) {
    return useCreateItem<AgentSkill>(useSkillsQueryKey(), get_skills_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateSkills()
        },
    })
}

export function useUpdateAgentSkill(agent_sid: string) {
    return useUpdateItem<AgentSkill>(useSkillsQueryKey(), get_skills_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateSkills()
        },
    })
}

export function useDeleteAgentSkill(agent_sid: string) {
    return useDeleteItem(useSkillsQueryKey(), get_skills_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateSkills()
        },
    })
}

export function invalidateSkills() {
    return queryClient.invalidateQueries([SKILLS_LIST_NAME, getCurrentStackId()])
}
