import React, { useEffect, useState } from 'react'

import {
    useCreateToolConnection,
    useDeleteToolConnection,
    useUpdateToolConnection,
} from 'data/hooks/agents/tool_connections'
import { Agent, AgentIntegrationsDto, AgentToolConnectionDto } from 'data/hooks/agents/types'
import { useCreateNangoSessionToken } from 'data/hooks/dataConnections/useCreateNangoSessionToken'
import { useDeleteConfirmationModal } from 'features/Agents/hooks/useDeleteConfirmationModal'
import { getNangoClient } from 'features/DataConnections/getNangoClient'

import { useToast } from 'v2/ui/components/useToast'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { Body, Headline } from 'ui/components/Text'

type ConfigureConnectionFormProps = {
    agent: Agent
    connection?: AgentToolConnectionDto
    integration: AgentIntegrationsDto
    handleClose: () => void
}

export function ConfigureConnectionForm({
    agent,
    connection,
    integration,
    handleClose,
}: ConfigureConnectionFormProps) {
    const toast = useToast()

    // Either set the connection, or wait until we create it
    const [toolConnection, setToolConnection] = useState<AgentToolConnectionDto | null>(
        connection || null
    )

    const createToolConnnection = useCreateToolConnection(agent._sid)

    const { mutateAsync: createSessionToken } = useCreateNangoSessionToken({
        onError: () => {
            toast({
                title: 'There was a problem setting up a new connection. Please try again or contact support.',
                type: 'error',
            })
        },
    })

    const handleAuthenticate = async (integration: AgentIntegrationsDto) => {
        // Make the connection with Nango, and save the connection id
        const integration_id = integration.integration_id
        const sessionToken = await createSessionToken()
        console.log('Handling for integration_id', integration_id)
        const nango = getNangoClient(integration_id, sessionToken.token)
        try {
            const authResult = await nango.auth(integration_id)
            toast({
                title: `${integration_id} connected successfully`,
                status: 'success',
            })
            return await createToolConnection(
                authResult.connectionId,
                integration.integration_id,
                integration.name
            )
        } catch (error) {
            console.error('Authentication error:', error)
            toast({
                title: `Failed to connect ${integration_id}`,
                status: 'error',
            })
        }
    }

    const createToolConnection = async (
        connectionId: string,
        integrationId: string,
        description: string
    ) => {
        const toolConnection = await createToolConnnection.mutateAsync({
            integration_id: integrationId,
            nango_connection_id: connectionId,
            description: description,
            config_data: {
                enabled: true,
            },
        })
        setToolConnection(toolConnection)
    }

    // Handle changing the description
    const [description, setDescription] = useState(toolConnection?.description || '')
    const [isDirty, setIsDirty] = useState(false)
    useEffect(() => {
        if (toolConnection) {
            setDescription(toolConnection.description)
        }
    }, [toolConnection])
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
        setIsDirty(true)
    }
    const { mutateAsync: updateToolConnection } = useUpdateToolConnection(agent._sid)

    const handleSaveDescription = async () => {
        if (!toolConnection) return
        setIsDirty(false)
        await updateToolConnection({
            id: toolConnection._sid,
            patch: {
                description: description,
            },
        })
    }

    const { mutateAsync: deleteToolConnection } = useDeleteToolConnection(agent._sid)

    const { openDeleteModal, DeleteModal } = useDeleteConfirmationModal({
        onDelete: async () => {
            if (!toolConnection) return
            await deleteToolConnection(toolConnection._sid)
            toast({
                title: 'Connection deleted successfully',
                status: 'success',
            })
            handleClose()
        },
        title: 'Delete Connection',
        getSubtitle: (name) =>
            `Are you sure you want to delete ${name}? This action cannot be undone.`,
        errorMessage:
            'There was a problem deleting the connection. Please try again or contact support.',
    })

    const handleDelete = () => {
        if (!toolConnection) return
        openDeleteModal(toolConnection.description)
    }

    return (
        <Box flex column gap="m">
            <Field label="Connection" width="5xl" shrink>
                <ConnectionCard
                    integration={integration}
                    isConnected={!!toolConnection}
                    handleAuthenticate={() => handleAuthenticate(integration)}
                />
            </Field>

            <Field label="Description" width="5xl" shrink>
                <Input
                    disabled={!toolConnection}
                    placeholder="Enter tool description"
                    onChange={handleDescriptionChange}
                    defaultValue={description}
                />
            </Field>

            <Box>
                <Button disabled={!isDirty} onClick={handleSaveDescription}>
                    Save
                </Button>
                {toolConnection && (
                    <Button variant="ghost" startIcon={{ name: 'Trash' }} onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </Box>
            <DeleteModal />
        </Box>
    )
}

const ConnectionCard = ({
    integration,
    isConnected,
    handleAuthenticate,
}: {
    integration: AgentIntegrationsDto
    isConnected: boolean
    handleAuthenticate: () => void
}) => {
    return (
        <Box
            flex
            justifyContent="space-between"
            alignItems="center"
            p="m"
            borderWidth="base"
            borderRadius="m"
        >
            <Box flex column gap="xs">
                <Box flex center gap="s">
                    <Box css={{ color: 'var(--colors-textSubtle)' }}>
                        <Icon
                            name={
                                integration.integration_id === 'google-mail'
                                    ? 'Mail'
                                    : ('Cog' as any)
                            }
                        />
                    </Box>
                    <Headline size="xs">{integration.name}</Headline>
                </Box>
                <Body size="s" css={{ color: 'var(--colors-textSubtle)' }}>
                    {isConnected ? 'Connected' : 'Not connected'}
                </Body>
            </Box>
            <Button
                variant={isConnected ? 'secondary' : 'primary'}
                disabled={isConnected}
                onClick={handleAuthenticate}
            >
                {isConnected ? 'Disconnect' : 'Connect'}
            </Button>
        </Box>
    )
}
