import React from 'react'

import { CurrencyAttributeDisplay } from 'features/views/attributes/CurrencyAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type CurrencyAttributeProps = {}

export const CurrencyAttribute: FieldsWidgetAttributeComponent<number, CurrencyAttributeProps> = ({
    value,
    field,
    isLoading,
    ...props
}) => {
    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <CurrencyAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                size="m"
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}
