import React, { useState } from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

type UseDeleteConfirmationModalProps = {
    onDelete: () => Promise<void>
    title?: string
    getSubtitle?: (itemName?: string) => React.ReactNode
    errorMessage?: string
}

/*
    So this hook could probably be a generic hook that can be used for any delete confirmation.
    But for now I'm just putting it here while I get used to the codebase
*/

export function useDeleteConfirmationModal({
    onDelete,
    title = 'Delete Item',
    getSubtitle = (itemName) =>
        `Are you sure you want to delete ${itemName || 'this item'}? This action cannot be undone.`,
    errorMessage = 'There was a problem deleting the item. Please try again or contact support.',
}: UseDeleteConfirmationModalProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [itemName, setItemName] = useState<string>()
    const [isError, setIsError] = useState(false)

    const openDeleteModal = (name?: string) => {
        setItemName(name)
        setIsOpen(true)
        setIsError(false)
    }

    const handleDelete = async () => {
        try {
            await onDelete()
            setIsOpen(false)
        } catch {
            setIsError(true)
        }
    }

    const DeleteModal = () => (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalContent>
                <ModalHeader
                    subtitle={getSubtitle(itemName)}
                    title={title}
                    showCloseButton={true}
                />
                <ModalFooter flexDirection="column">
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button size="l" variant="ghost">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button size="l" variant="destructive" onClick={handleDelete}>
                            Delete
                        </Button>
                    </ModalFooterButtonGroup>
                    {isError && (
                        <Box mt="l">
                            <Body size="m" color="textError">
                                {errorMessage}
                            </Body>
                        </Box>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

    return {
        openDeleteModal,
        DeleteModal,
    }
}
