import React from 'react'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { LayoutEditorSaveButton } from './LayoutEditorSaveButton'

type LayoutEditorViewPaneProps = {
    title: string
}

export const LayoutEditorViewPane: React.FC<LayoutEditorViewPaneProps> = ({ title, children }) => {
    return (
        <ThemeProvider theme="admin" py="l" alignSelf="stretch" height="full">
            <Box
                display="grid"
                style={{ gridTemplateRows: 'auto 1fr auto' }}
                alignSelf="stretch"
                height="full"
            >
                <Box mb="3xl" px="l">
                    <Headline size="2xs">{title}</Headline>
                </Box>
                {children}
                <LayoutEditorSaveButton px="l" />
            </Box>
        </ThemeProvider>
    )
}
