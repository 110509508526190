import { Editor, Extension, findChildren } from '@tiptap/core'
import { Plugin } from '@tiptap/pm/state'
import * as client from 'filestack-js'
import shortid from 'shortid'

import settings from 'app/settings'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        imageManager: {
            insertImageFile: (item: File, atPos?: number) => ReturnType
        }
    }
}

type ImageManagerOptions = {
    allowPaste?: boolean
    allowDrop?: boolean
    registerBlock?: (message: string) => () => void
}

export function createImageManagerExtension(options: ImageManagerOptions = {}) {
    return Extension.create({
        name: 'imageManager',

        addStorage() {
            return {
                activeUploads: new Set<() => void>(),
            }
        },

        addCommands() {
            return {
                insertImageFile:
                    (item: File, atPos?: number) =>
                    ({ commands, editor, state }) => {
                        const isValid = validateImageFile(item)
                        if (!isValid) return false

                        let unregister: () => void | undefined
                        if (options.registerBlock) {
                            unregister = options.registerBlock('Uploading image...')
                            this.storage.activeUploads.add(unregister)
                        }

                        // Inject the image using the blob src so we get immediate display.
                        const node = createImageNodeFromFile(item, editor)
                        const nodeId = node.attrs.id

                        commands.command(({ tr }) => {
                            if (typeof atPos === 'number') {
                                tr.insert(atPos, node)
                            } else {
                                tr.replaceSelectionWith(node)
                            }

                            return true
                        })

                        uploadImageFile(item)
                            .then((filestackResponse) => {
                                updateImageNodeWithUrl(nodeId, filestackResponse.url, editor)
                                if (unregister) {
                                    unregister()
                                    this.storage.activeUploads.delete(unregister)
                                }
                            })
                            .catch((err) => {
                                // Don't throw an error if the node has been deleted.
                                const { doc } = state
                                const node = findChildren(
                                    doc,
                                    (node) => node.attrs.id === nodeId
                                )?.[0]
                                if (!node) return

                                console.error(err)
                                window.alert(
                                    'There was a problem uploading your image, please try again.'
                                )
                            })

                        return true
                    },
            }
        },

        addProseMirrorPlugins() {
            const editor = this.editor

            const plugins: Plugin[] = []

            if (options.allowPaste) {
                plugins.push(
                    new Plugin({
                        props: {
                            handlePaste(_view, event) {
                                const items = Array.from(event.clipboardData?.items || [])

                                for (const item of items) {
                                    if (item.type.startsWith('image')) {
                                        editor.commands.insertImageFile(item.getAsFile() as File)
                                        return true
                                    }
                                }

                                return false
                            },
                        },
                    })
                )
            }

            if (options.allowDrop) {
                plugins.push(
                    new Plugin({
                        props: {
                            handleDrop(view, event) {
                                const targetPos = view.posAtCoords({
                                    left: event.clientX,
                                    top: event.clientY,
                                })
                                if (!targetPos) return false // not handled

                                const items = Array.from(event.dataTransfer?.items || [])
                                for (const item of items) {
                                    if (item.type.startsWith('image')) {
                                        editor.commands.insertImageFile(
                                            item.getAsFile() as File,
                                            targetPos.pos
                                        )
                                        return true // handled
                                    }
                                }
                                return false // not handled use default behaviour
                            },
                        },
                    })
                )
            }

            return plugins
        },
    })
}

function validateImageFile(item: File): boolean {
    const filesize = (item.size as number) / 1024 / 1024 // get the filesize in MB
    // check image under 10MB
    if (filesize >= 10) {
        window.alert('Images need to be less than 10mb in size.')

        return false
    }

    return true
}

async function uploadImageFile(item: File): Promise<{ url: string }> {
    const filestackClient = client.init(settings.FILESTACK.key)
    const filestackResponse = await filestackClient.upload(item)

    return filestackResponse
}

function createImageNodeFromFile(item: File, editor: Editor) {
    const _URL = window.URL || window.webkitURL
    const img = new Image() /* global Image */
    img.src = _URL.createObjectURL(item)

    // Tag it with a unique ID so we can find it after the upload.
    const id = shortid.generate()

    const imageNodeSchema = editor.schema.nodes.image
    const node = imageNodeSchema.create({
        src: img.src,
        'data-is-loading': true,
        id,
    })

    return node
}

function updateImageNodeWithUrl(id: string, url: string, editor: Editor) {
    editor.commands.command(({ tr, state }) => {
        const { doc } = state

        const node = findChildren(doc, (node) => node.attrs.id === id)?.[0]
        if (!node) return false

        tr.setNodeMarkup(node.pos, null, {
            src: url,
            'data-is-loading': false,
        })

        return true
    })
}
