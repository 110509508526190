import React from 'react'

import { PercentageAttributeDisplay } from 'features/views/attributes/PercentageAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { FieldsWidgetAttributeComponent } from './types'

type PercentageAttributeProps = {}

export const PercentageAttribute: FieldsWidgetAttributeComponent<
    number,
    PercentageAttributeProps
> = ({ value, field, isLoading, ...props }) => {
    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <PercentageAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                whiteSpace="nowrap"
                size="m"
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}
