import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { ChatTool } from './openAiTypes'

type ChatCompletion = {
    content: string
    id: string
}
function buildTools(tools: ChatTool[]) {
    return tools.map((tool) => ({
        type: 'function',
        function: tool,
    }))
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export async function getChatCompletion(
    messages: any[],
    tools?: ChatTool[],
    response_format?: any,
    model?: string
): Promise<ChatCompletion> {
    const maxRetries = 3
    let retries = 0

    while (retries < maxRetries) {
        try {
            const response = await fetchWithAuth('ai/', {
                method: 'POST',
                body: JSON.stringify({
                    tools: tools ? buildTools(tools) : undefined,
                    messages,
                    response_format,
                    model,
                }),
                headers: { 'Content-Type': 'application/json' },
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            return (await response.json()) as ChatCompletion
        } catch (error) {
            retries++
            if (retries >= maxRetries) {
                throw error // If all retries are exhausted, throw the last error
            }
            console.warn(`Attempt ${retries} failed. Retrying in 1 second...`)
            await delay(1000) // Wait for 1 second before retrying
        }
    }

    throw new Error('Max retries reached') // Just here to make TS happy
}
