import React, { useState } from 'react'

import { DeleteSyncedObjConfirmationModal } from 'features/DataConnections/DataConnectionsManager/DeleteSyncedObjConfirmationModal'

import { Button } from 'v2/ui'

import { ButtonProps } from 'ui/components/Button'

type DeleteExternalTableButtonProps = {
    object: ObjectDto
} & ButtonProps

export const DeleteExternalTableButton: React.FC<DeleteExternalTableButtonProps> = ({
    object,
    ...buttonProps
}) => {
    const [isRemoveObjModalOpen, setIsRemoveObjModalOpen] = useState(false)

    return (
        <div>
            <Button
                icon="trash"
                onClick={() => {
                    setIsRemoveObjModalOpen(true)
                }}
                variant="Primary"
                buttonSize="sm"
                {...buttonProps}
            >
                Delete table
            </Button>

            <DeleteSyncedObjConfirmationModal
                isOpen={isRemoveObjModalOpen}
                onOpenChange={setIsRemoveObjModalOpen}
                object={object}
            />
        </div>
    )
}
