import React from 'react'

import { getUrl, Urls } from 'app/UrlService'
import { useAgents } from 'data/hooks/agents/agents'
import { Agent } from 'data/hooks/agents/types'
import useLDFlags from 'data/hooks/useLDFlags'
import {
    AppNavigationItemIconStyles,
    AppNavigationItemIconWrapperStyle,
    AppNavigationItemLabelStyles,
    AppNavigationItemStyles,
    NavigationItemHoverStyle,
} from 'features/navigation/Navigation.css'
import { NavigationItem } from 'features/navigation/NavigationItem'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { AgentChatDropdown } from './chat/AgentChatDropdown'
import { AgentIcon } from './chat/AgentIcon'
import { EditAgentModal } from './configuration/modal/EditAgentModal'

export function SidebarAgentsPanel() {
    const { data: agents } = useAgents()
    const { flags } = useLDFlags()

    if (!flags.agents) return null
    const hasAgents = !!agents?.length
    return (
        <Box flex column px="m" gap="2xs">
            <Box flex center justifyContent="space-between">
                <Body weight="bold" color="textWeaker">
                    Agents
                </Body>
                {hasAgents && (
                    <EditAgentModal
                        trigger={
                            <Button
                                variant="secondary"
                                size="xs"
                                startIcon={{ name: 'Plus' }}
                                iconOnly
                            />
                        }
                    />
                )}
            </Box>
            {agents?.map((agent) => <AgentItem key={agent._sid} agent={agent} />)}
            <Box style={{ display: !hasAgents ? 'block' : 'none' }}>
                <EditAgentModal
                    trigger={
                        <Button variant="secondary" size="xs" startIcon={{ name: 'Plus' }}>
                            Create Agent
                        </Button>
                    }
                />
            </Box>
        </Box>
    )
}

function AgentItem({ agent }: { agent: Agent }) {
    return (
        <AgentTreeItem agent={agent}>
            <AgentChatDropdown agent={agent}>
                <Icon color="textWeaker" name="MessageSquare" />
            </AgentChatDropdown>
        </AgentTreeItem>
    )
}

function AgentTreeItem({ agent, children }: { agent: Agent; children: React.ReactNode }) {
    const url = getUrl(`${Urls.Agents}/${agent._sid}/chat`)
    const isActive = url == window.location.pathname
    const hasChildren = false
    const hasIcon = true
    return (
        <NavigationItem
            to={url}
            as={hasChildren ? 'div' : undefined}
            role={hasChildren ? 'button' : undefined}
            className={AppNavigationItemStyles.styleFunction({
                isActive,
                hasChildren,
                hasIcon,
            })}
            trim
            label={
                <Box flex center gap="s" trim>
                    {hasIcon && (
                        <Box
                            color="gray900"
                            position="relative"
                            className={AppNavigationItemIconWrapperStyle}
                        >
                            <Box
                                className={AppNavigationItemIconStyles.styleFunction({ isActive })}
                            >
                                <AgentIcon agent={agent} />
                            </Box>
                        </Box>
                    )}
                    <Body
                        size="m"
                        weight={isActive ? 'bold' : 'medium'}
                        pl={hasIcon ? 0 : 'xs'}
                        trim
                        className={AppNavigationItemLabelStyles.styleFunction({
                            isActive,
                        })}
                    >
                        {agent.title}
                    </Body>
                </Box>
            }
            rightSlotContent={
                <Box
                    className={NavigationItemHoverStyle}
                    onClick={stopPropagation}
                    flex
                    center
                    gap="2xs"
                    noShrink
                >
                    {children}
                </Box>
            }
        />
    )
}
