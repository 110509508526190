import { useQuery, UseQueryOptions } from 'react-query'

import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { fetchAndReturn } from 'data/utils/utils'

import { AgentIntegrationsDto } from './types'

const LIST_NAME = 'agents/integrations'

function useQueryKey() {
    return useQueryKeyBuilder([LIST_NAME], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type AgentIntegrationsData = {
    integrations: AgentIntegrationsDto[]
}

export const useAgentIntegrations = (options: UseQueryOptions<AgentIntegrationsData> = {}) => {
    const queryKey = useQueryKey()

    return useQuery<AgentIntegrationsData>(
        queryKey,
        async () => {
            return fetchAndReturn(`agents/integrations/`) as Promise<AgentIntegrationsData>
        },
        options
    )
}

export const invalidateAgentIntegrations = () => {
    return queryClient.invalidateQueries([LIST_NAME])
}
