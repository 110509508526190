import React from 'react'

import { INTEGRATION_ID_TO_NAME } from 'features/DataConnections/constants'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Popup, PopupContent, PopupInner, PopupTitle, PopupTrigger } from 'ui/components/Popup'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

type DetailedSyncInfoStageProp = {
    title: string
    description: string | React.ReactNode
    isCompleted: boolean
}
const DetailedSyncInfoStage: React.FC<DetailedSyncInfoStageProp> = ({
    title,
    description,
    isCompleted,
}) => {
    return (
        <Box flex style={{ minHeight: '30px', marginBottom: theme.space.s }}>
            <Box flex>
                {isCompleted ? (
                    <Icon name="CheckCircle2" color="success600" size="l" />
                ) : (
                    <Spinner size="s" />
                )}
            </Box>
            <Box flex flexDirection="column" marginLeft="m">
                <Body size="m" weight="bold" marginBottom="s">
                    {title}
                </Body>
                <Body
                    size="s"
                    style={{
                        // this border is the divider below the icon of each stage
                        borderLeftWidth: '2px',
                        borderColor: 'gray300',
                        color: theme.color.gray500,
                        marginLeft: '-18px',
                        paddingLeft: '18px',
                    }}
                >
                    {description}
                </Body>
            </Box>
        </Box>
    )
}

type DCStatusesWithoutErrors = Exclude<
    DataConnectionDto['status'],
    'AUTHENTICATION_ERROR' | 'AUTHORIZATION_ERROR' | 'UNKNOWN_ERROR'
>
type DetailedSyncInfoProp = {
    integrationId: ExternalIntegrationId
    status: DataConnectionDto['status']
    syncProgress: DataConnectionDto['sync_progress']
}

const DetailedSyncInfo: React.FC<DetailedSyncInfoProp> = ({
    integrationId,
    status,
    syncProgress,
}) => {
    if (['AUTHENTICATION_ERROR', 'AUTHORIZATION_ERROR', 'UNKNOWN_ERROR'].includes(status)) {
        return null
    }

    const statusToStageInfo: Record<
        DCStatusesWithoutErrors,
        { title: string; description: string | React.ReactNode }
    > = {
        SYNC_NOT_STARTED: {
            title: `Connecting to ${INTEGRATION_ID_TO_NAME[integrationId]}`,
            description: 'This can take up to a minute',
        },
        NANGO_STARTED: {
            title: 'Gathering data',
            description: 'Retrieving tables, fields, and records',
        },
        NANGO_COMPLETED: {
            title: 'Starting sync',
            description: 'Starting schema and data sync',
        },
        SYNCING_SCHEMA: {
            title: 'Syncing',
            description: (
                <>
                    {syncProgress.num_total_objects} tables, {syncProgress.num_unchanged_objects}{' '}
                    unchanged, {syncProgress.num_created_objects} created,{' '}
                    {syncProgress.num_updated_objects} updated, {syncProgress.num_deleted_objects}{' '}
                    removed
                    <br /> <br />
                    {syncProgress.num_total_fields} columns, {syncProgress.num_unchanged_fields}{' '}
                    unchanged, {syncProgress.num_created_fields} created,{' '}
                    {syncProgress.num_updated_fields} updated, {syncProgress.num_deleted_fields}{' '}
                    removed
                </>
            ),
        },
        SCHEMA_SYNC_COMPLETED: {
            title: 'Import completed',
            description: '',
        },
    }

    const statusOrder: Array<DCStatusesWithoutErrors> = [
        'SYNC_NOT_STARTED',
        'NANGO_STARTED',
        'NANGO_COMPLETED',
        'SYNCING_SCHEMA',
        'SCHEMA_SYNC_COMPLETED',
    ]

    const currentStatusIndex = statusOrder.indexOf(status as DCStatusesWithoutErrors)

    const stages = statusOrder.slice(0, currentStatusIndex + 1).map((status, index) => ({
        title: statusToStageInfo[status].title,
        description: statusToStageInfo[status].description,
        isCompleted:
            index < currentStatusIndex ||
            (index === currentStatusIndex && status === 'SCHEMA_SYNC_COMPLETED'),
    }))

    return (
        <>
            {stages.map((stage, index) => (
                <DetailedSyncInfoStage
                    title={stage.title}
                    description={stage.description}
                    isCompleted={stage.isCompleted}
                    key={index}
                />
            ))}
        </>
    )
}

type DataConnectionSyncInfoProps = {
    isOpen: boolean
    onOpenChange: (s: boolean) => void
    integrationId: ExternalIntegrationId
    earliestStatus: DataConnectionDto['status']
    dataConnections: DataConnectionDto[]
}

export const DataConnectionSyncDetailPopup: React.FC<DataConnectionSyncInfoProps> = ({
    isOpen,
    onOpenChange,
    integrationId,
    earliestStatus,
    dataConnections,
    children,
}) => {
    const aggregateSyncProgress: DataConnectionDto['sync_progress'] = {
        num_total_objects: 0,
        num_created_objects: 0,
        num_updated_objects: 0,
        num_unchanged_objects: 0,
        num_deleted_objects: 0,

        num_total_fields: 0,
        num_created_fields: 0,
        num_updated_fields: 0,
        num_unchanged_fields: 0,
        num_deleted_fields: 0,
    }

    dataConnections.forEach((dc) => {
        Object.keys(aggregateSyncProgress).forEach((key) => {
            const syncKey = key as keyof DataConnectionDto['sync_progress']
            if (dc.sync_progress && syncKey in dc.sync_progress) {
                aggregateSyncProgress[syncKey] += dc.sync_progress[syncKey]
            }
        })
    })

    return (
        <Popup open={isOpen} onOpenChange={onOpenChange}>
            <PopupTrigger
                onMouseEnter={() => {
                    onOpenChange(true)
                }}
                onMouseLeave={() => {
                    onOpenChange(false)
                }}
            >
                {children}
            </PopupTrigger>
            <PopupContent
                id={'amir-test-3'}
                sideOffset={0}
                side="bottom"
                align="end"
                style={{
                    maxWidth: '330px',
                }}
            >
                <PopupTitle>{INTEGRATION_ID_TO_NAME[integrationId]} sync</PopupTitle>

                <PopupInner>
                    <DetailedSyncInfo
                        integrationId={integrationId}
                        status={earliestStatus}
                        syncProgress={aggregateSyncProgress}
                    />
                </PopupInner>
            </PopupContent>
        </Popup>
    )
}
