import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { SystemTool, ToolConfig } from 'data/hooks/agents/types'

type ConfigureToolFormWrapperProps = {
    tool: SystemTool
    config?: ToolConfig
    children: React.ReactNode
}

export function ConfigureToolFormWrapper({
    tool,
    config,
    children,
}: ConfigureToolFormWrapperProps) {
    const methods = useForm<ToolConfig>({
        defaultValues: config || {
            tool_id: tool.api_name,
            api_name: tool.api_name,
            name: tool.name,
            description: tool.description,
            supplied_inputs: {},
        },
    })

    return <FormProvider {...methods}>{children}</FormProvider>
}
