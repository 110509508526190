import * as Y from 'yjs'

import type { DetailViewLayout } from 'features/views/DetailView/types'

import { IconName } from 'ui/components/Icon/Icon'

import { WIDGET_CATEGORIES } from './constants'

export type WidgetCategory = keyof typeof WIDGET_CATEGORIES

export type WidgetComponentProps<W extends Widget> = {
    widget: W
    isEditing: boolean
    isSelected: boolean
}

export type WidgetComponent<W extends Widget, P = {}> = React.FC<WidgetComponentProps<W> & P>

export type WidgetAdminControlsOpenDetailPaneParams<W extends Widget> = {
    component: WidgetAdminControlsComponent<W>
    label?: string
    onBackClick?: () => void
}

export type WidgetAdminControlsProps<W extends Widget> = {
    widget: W
    onChange: (tr: (attrs: Y.Map<any>) => void) => void
    openDetailPane: (params: WidgetAdminControlsOpenDetailPaneParams<W>) => void
    closeDetailPane: () => void
}

export type WidgetAdminControlsComponent<W extends Widget = any, P = {}> = React.FC<
    WidgetAdminControlsProps<W> & P
>

export type WidgetSchema<W extends Widget = any> = {
    label: string
    icon: IconName
    category: WidgetCategory
    component: WidgetComponent<W>
    adminControlsComponent?: WidgetAdminControlsComponent<W>
    defaultAttrs?: Partial<W['attrs']> | (() => Partial<W['attrs']> | undefined)
    isContainer?: boolean
}

export type WidgetAreas = Record<string, Widget[]>

export type Widget<T = unknown> = {
    type: string
    id: string
    children: WidgetAreas
    attrs: T
}

export type BaseLayout = {
    schemaVersion: number
    children: WidgetAreas
}

export type Layout = DetailViewLayout

export type LayoutEditorSchema = {
    version: number
    widgets: Record<string, WidgetSchema>
}

export enum DraggableTypes {
    Widget = 'widget',
    WidgetArea = 'widgetArea',
    WidgetPickerItem = 'widgetPickerItem',
}
