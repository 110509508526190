import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { FieldsWidget, FieldsWidgetAdminControls } from './FieldsWidget'
import { FieldsWidgetType } from './fieldWidgetTypes'

export const FieldsWidgetMeta: WidgetSchema<FieldsWidgetType> = {
    category: 'data',
    label: 'Fields',
    icon: 'List',
    component: FieldsWidget,
    adminControlsComponent: FieldsWidgetAdminControls,
    defaultAttrs: {
        labelPlacement: 'top',
        showFieldIcon: false,
        fields: [],
        showAllFields: true,
    },
}
