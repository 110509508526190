import React, { useContext } from 'react'

import { PathContext } from 'features/utils/PathContext'
import { DetailViewControls } from 'features/views/DetailView/DetailViewControls'
import { RecordManagerContextProvider } from 'features/views/RecordManager/RecordManagerContext'

import { useLayoutEditorControlsState } from './hooks/useLayoutEditorControlsState'
import { LayoutEditorWidgetControls } from './LayoutEditorWidgetControls'

type LayoutEditorControlsProps = {}

export const LayoutEditorControls: React.FC<LayoutEditorControlsProps> = () => {
    const { viewType, hasWidgetSelected, isSchemaOutdated } = useLayoutEditorControlsState()

    // In case there is a record in the path, we need to provide it to the RecordManagerContextProvider.
    const { recordId: recordSid } = useContext(PathContext)

    if (isSchemaOutdated) return null

    return (
        <RecordManagerContextProvider recordSid={recordSid} supportUnsavedChangesModal={false}>
            {hasWidgetSelected && <LayoutEditorWidgetControls />}
            {!hasWidgetSelected && viewType === 'detail' && <DetailViewControls />}
        </RecordManagerContextProvider>
    )
}
