import { useMemo } from 'react'

import { useTipTapDocumentContent } from 'features/tiptap/useTipTapDocumentContent'
import { RichTextValue } from 'features/views/attributes/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

type UseRichTextAttributeStateProps = {
    value?: RichTextValue
    isLoading?: boolean
}

export function useRichTextAttributeState({ value, isLoading }: UseRichTextAttributeStateProps) {
    const isEmpty = !isLoading && (!value || (!!value && !value?.plainTextContent))

    const content = useTipTapDocumentContent({
        content: value?.content ?? null,
        format: value?.format ?? 'tiptap',
    })

    const { record } = useRecordManagerContext()
    const { object } = useLayoutEditorContext()

    return useMemo(
        () => ({
            isEmpty,
            content,
            recordSid: record?._sid,
            stackSid: object?.stack_id,
        }),
        [isEmpty, content, record?._sid, object?.stack_id]
    )
}
