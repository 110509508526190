import React, { useState } from 'react'

import { Button, Flex, Input } from 'v2/ui'

type RenameNativeTableFormProps = {
    object: ObjectDto
    onChangeObject: (data: Partial<ObjectDto>) => void
}
export const RenameNativeTableForm: React.FC<RenameNativeTableFormProps> = ({
    object,
    onChangeObject,
}) => {
    const [objectName, setObjectName] = useState(object?.name)

    return (
        <Flex>
            <Input
                w="310px"
                onChange={(e: any) => setObjectName(e.target.value)}
                value={objectName}
            />
            <Button
                variant="Primary"
                buttonSize="sm"
                style={{ marginLeft: '10px' }}
                isDisabled={!objectName || objectName === object?.name}
                onClick={() => {
                    onChangeObject({ name: objectName })
                }}
            >
                Save
            </Button>
        </Flex>
    )
}
