import { QueryKey, useMutation, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    buildQueryKey,
    queryClient,
    useCanRunStackScopedQueries,
    useQuery,
    useQueryKeyBuilder,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import {
    ChatMessageBase,
    ChatMessageRemove,
    ChatUserMessage,
} from 'features/AiAppBuilder/chatUtils/chatTypes'
import { ChatMessageContentPart } from 'features/AiAppBuilder/chatUtils/openAiTypes'

import { ToolCallResult } from './types'

const CHAT_LIST_NAME = 'useAgentConversationChat'
const getEndpoint = (agent_sid: string, conversation_sid: string) =>
    `agents/${agent_sid}/conversations/${conversation_sid}/chat/`

function useChatQueryKey(conversationSid: string) {
    return useQueryKeyBuilder([CHAT_LIST_NAME, conversationSid], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

export type ConversationResponseData = {
    messages: (ChatMessageBase | ChatMessageRemove)[]
    interrupt_messages: ChatMessageBase[]
    tool_call_results: ToolCallResult[]
    conversation_sid: string
    pendingMessage?: ChatUserMessage
}

type MessagesOptionsType = UseQueryOptions<
    ConversationResponseData,
    unknown,
    ConversationResponseData,
    QueryKey
>

export function useAgentConversationChatState(
    agent_sid: string,
    conversation_sid?: string,
    options: MessagesOptionsType = {}
) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as MessagesOptionsType),
        keepPreviousData: false,
        ...options,
        enabled: enabled && !!conversation_sid,
    }
    return useQuery<ConversationResponseData>(
        useChatQueryKey(conversation_sid || ''),
        getEndpoint(agent_sid, conversation_sid || ''),
        query_config
    )
}

type PostMessageArguments = {
    agentSid: string
    conversationSid?: string
    message: string | Array<ChatMessageContentPart>
}
export function usePostChatMessage() {
    return useMutation(
        async ({ agentSid, conversationSid, message }: PostMessageArguments) => {
            const endpoint = getEndpoint(agentSid, conversationSid || 'new')

            //throw new Error('Not implemented')
            const response = await fetchWithAuth(endpoint, {
                method: 'POST',
                body: JSON.stringify({ message }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.status >= 400) {
                return Promise.reject(response)
            }

            return response.json() as unknown as ConversationResponseData
        },
        {
            onMutate: async ({ conversationSid, message }) => {
                if (!conversationSid) {
                    return {}
                }

                const tempId = 'temp-' + Date.now()

                // Create optimistic message
                const optimisticMessage: ChatUserMessage = {
                    id: tempId,
                    type: 'human',
                    content: message,
                    isSending: true,
                }

                // Add optimistic message to cache
                queryClient.setQueryData<ConversationResponseData>(
                    buildQueryKey([CHAT_LIST_NAME, conversationSid || ''], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => ({ ...old!, pendingMessage: optimisticMessage })
                )

                return { tempId }
            },
            onSuccess: (data, __) => {
                const { conversation_sid } = data

                // Update cache with actual messages
                queryClient.setQueryData<ConversationResponseData>(
                    buildQueryKey([CHAT_LIST_NAME, conversation_sid], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => {
                        let finalMessages = (old?.messages ?? []).filter(
                            (m) => !data.messages?.find((m2) => m2.id === m.id)
                        )

                        for (const message of data.messages) {
                            if (message.type === 'remove') {
                                finalMessages = finalMessages.filter((m) => m.id !== message.id)
                            } else {
                                finalMessages.push(message)
                            }
                        }

                        return {
                            ...old!,
                            messages: finalMessages,
                            tool_call_results: [
                                ...(old?.tool_call_results ?? []),
                                ...data.tool_call_results,
                            ],
                            interrupt_messages: data.interrupt_messages,
                            pendingMessage: undefined,
                        }
                    }
                )

                invalidateMessages(conversation_sid)
            },
            onError: (_, { conversationSid }, context) => {
                const tempId = context?.tempId

                if (!conversationSid) {
                    return
                }

                // Mark message as failed
                queryClient.setQueryData<ConversationResponseData>(
                    buildQueryKey([CHAT_LIST_NAME, conversationSid], {
                        includeAuthKeys: true,
                        includeStackId: true,
                    }),
                    (old) => ({
                        ...old!,
                        pendingMessage:
                            old?.pendingMessage && old?.pendingMessage?.id === tempId
                                ? { ...old!.pendingMessage, isSending: false, sendingFailed: true }
                                : old?.pendingMessage,
                    })
                )
            },
        }
    )
}

export function cancelPendingMessage(conversationSid: string, messageId: string) {
    queryClient.setQueryData<ConversationResponseData>(
        buildQueryKey([CHAT_LIST_NAME, conversationSid], {
            includeAuthKeys: true,
            includeStackId: true,
        }),
        (old) => ({
            ...old!,
            messages:
                old?.messages?.filter(
                    (msg) =>
                        !(msg.id === messageId && ('isSending' in msg || 'sendingFailed' in msg))
                ) ?? [],
        })
    )
}

export function invalidateMessages(conversationSid: string) {
    return queryClient.invalidateQueries([CHAT_LIST_NAME, getCurrentStackId(), conversationSid])
}
