/* Code Quality: Not audited */

import React, { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import get from 'lodash/get'
import queryString from 'qs'

import { AppUserContext } from 'app/AppUserContext'
import { Rights } from 'app/appUserContextConstants'
import { getUrl, Urls } from 'app/UrlService'
import { useInitialMetadata } from 'data/hooks/useInitialMetadata'
import useLDFlags from 'data/hooks/useLDFlags'
import { withObjects } from 'data/wrappers/withObjects'
import { withUser } from 'data/wrappers/withUser'
import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'
import { NewAppSplashScreen } from 'features/admin/NewAppSplashScreen'
import { ThemeWrapper } from 'features/pages/blocks/styles/ThemeWrapper'
import Editor from 'features/pages/editor/Editor'
import BlockTree from 'features/pages/tree/BlockTree'
import defaultBlockTree from 'features/pages/tree/defaultBlockTree'
import defaultTree from 'features/pages/tree/defaultTree'
import { PanelFloatingButton } from 'features/studio/ui/PanelFloatingButton'
import { LayoutEditor } from 'features/views/LayoutEditor/LayoutEditor'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import publicAsset from 'utils/publicAsset'

import { Alert, Flex } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import PageFrame from './PageFrame'

import 'react-toastify/dist/ReactToastify.css'

const fakeApp = { options: {} }

function Page(props) {
    const { hasRight } = useContext(AppUserContext)
    const { isStackLoading, isFetchingStack } = useInitialMetadata()
    const unsubscribeFromHistory = useRef()
    const history = useHistory()
    const location = useLocation()

    const isLoading = !!(isStackLoading || isFetchingStack)

    /*
        Page does the following:

        - Work out which page to view based on the url
        - Collects in some block context information
            - Query String
            - Url params
            - App settings e.g. theme
        - Potentially renders the editor frame, if edit mode is on
        - Adds on the correct theme
        - Renders the Tree
    */

    useEffect(() => {
        if (unsubscribeFromHistory.current) return
        unsubscribeFromHistory.current = history.listen(() => {
            window.Appcues && window.Appcues.page()
        })
    }, [history])

    useEffect(() => {
        return () => {
            if (unsubscribeFromHistory.current) {
                unsubscribeFromHistory.current()
            }
        }
    }, [])

    // componentDidUpdate = () => {
    //     const domNode = ReactDOM.findDOMNode(this)
    //     if (domNode && domNode.scrollIntoView) domNode.scrollIntoView()
    // }

    const getClassNames = () => {
        const classNames = [STYLE_CLASSES.PAGE]

        const { page, objects } = props

        if (!page || !objects) return STYLE_CLASSES.PAGE

        const object = objects.find((o) => o._sid === page?.object_id)

        if (object) {
            classNames.push(`${STYLE_CLASSES.PAGE}-${object.api_name}`)
            classNames.push(`${STYLE_CLASSES.PAGE}-${page.api_name}`)

            const viewType = get(page, 'options.view_type')
            if (viewType == 'detail') {
                classNames.push(STYLE_CLASSES.DETAIL_PAGE)
            }
            if (viewType == 'list') {
                classNames.push(STYLE_CLASSES.LIST_PAGE)
            }
            if (viewType == 'create') {
                classNames.push(STYLE_CLASSES.CREATE_PAGE)
            }
        }

        return classNames.join(' ')
    }

    const {
        page,
        app,
        captures,
        route,
        updatePage,
        noFrame,
        setConnectionEditorOpen,
        isConnectionEditorOpen,
        view,
        objects,
    } = props

    const object = objects.find((obj) => obj._sid === page?.object_id)

    const { setContextInfo } = useSlidingPane()
    // let the sliding pane know what object (if any) we are
    // now viewing
    useEffect(() => {
        setContextInfo({ objectId: object?._sid, viewId: view?._sid })
        return () => setContextInfo({ objectId: null })
    }, [object, setContextInfo, view?._sid])

    const deviceType = window.innerWidth > 768 ? 'desktop' : 'mobile'
    const pageIsEditing = location.hash === '#edit'
    const pageIsNewBlock = page?._object_id === 'block'

    const queryDict = useMemo(
        () => queryString.parse(location.search.slice(1)),
        [location.search],
        'queryDict'
    )
    const inputs = useMemo(
        () => {
            const inputs = {}
            if (pageIsEditing && props.page.options && props.page.options.inputs) {
                props.page.options.inputs.forEach(({ key, preview }) => {
                    inputs[key] = preview
                })
            }
            return inputs
        },
        [pageIsEditing, props.page],
        'inputs'
    )
    const urlParamsHash = Object.values(captures || {}).join(',')
    const context = useMemo(
        () => ({
            theme: app && app.theme,
            url: captures,
            query: queryDict,
            deviceType,
            page,
            object,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryDict, inputs, urlParamsHash], // watch for any url param changes
        'context'
    )

    const tree =
        page?.blocks && page?.blocks.length
            ? page.blocks
            : pageIsNewBlock
              ? JSON.parse(JSON.stringify(defaultBlockTree))
              : JSON.parse(JSON.stringify(defaultTree)) // Perform a JSON copy
    const favicon = (page && page.options.favicon) || (fakeApp && fakeApp.options.favicon)

    const { flags } = useLDFlags()

    // If we have no page, and the user has admin rights, redirect to
    // admin home
    if ((!page || isConnectionEditorOpen) && hasRight(Rights.Admin.Any) && !isLoading) {
        return <NewAppSplashScreen setIsNewConnectionEditorOpen={setConnectionEditorOpen} />
    }

    if (!page && !isLoading && location.pathname !== getUrl(Urls.Home)) {
        return <Redirect to={getUrl(Urls.Home)} />
    }

    if (!page) {
        return (
            <>
                <ThemeWrapper app={fakeApp} loadFromStack={false}>
                    {({ theme }) => {
                        const pageContext = {
                            ...context,
                            theme,
                        }
                        return (
                            <div
                                id="page"
                                className={`device-${context.deviceType} ${getClassNames()}`}
                                style={{
                                    width: '100%',
                                    minHeight: '100%',
                                    overflow: 'auto',
                                }}
                            >
                                <PageFrame
                                    context={{
                                        ...pageContext,
                                        editor: {
                                            isEditing: false,
                                            actions: {},
                                        },
                                    }}
                                    blockEntities={props.blockEntities}
                                    page={{ options: {} }}
                                    app={fakeApp}
                                    view={props.view}
                                >
                                    <Flex
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        {isLoading ? (
                                            <img
                                                alt=""
                                                src={publicAsset('/static/media/Spinner.svg')}
                                                style={{
                                                    marginLeft: 0,
                                                    marginRight: 8,
                                                    height: 64,
                                                }}
                                            />
                                        ) : (
                                            <Alert
                                                status="error"
                                                variant="left-accent"
                                                title="You do not have access to this page."
                                            ></Alert>
                                        )}
                                    </Flex>
                                </PageFrame>
                            </div>
                        )
                    }}
                </ThemeWrapper>
            </>
        )
    }

    if (flags.turboDetailView && view?.type === 'detail') {
        return <LayoutEditor page={page} view={view} />
    }

    return (
        <>
            <ThemeWrapper app={fakeApp} loadFromStack={false}>
                {({ theme }) => {
                    const passedProps = {
                        context,
                        theme,
                        fakeApp,
                        route,
                        updatePage,
                        tree,
                        pageIsEditing,
                        noFrame,
                        blockEntities: props.blockEntities,
                        classNames: getClassNames(),
                        page,
                        view: props.view,
                        noEditButton: props.noEditButton,
                        object,
                    }
                    return <RenderPage {...passedProps} />
                }}
            </ThemeWrapper>

            <Helmet>
                <link rel="shortcut icon" href={`${favicon}`} type="image/png" />
            </Helmet>
        </>
    )
}

const RenderPage = memo(function RenderPage({
    context,
    page,
    theme,
    object,
    route,
    updatePage,
    tree,
    pageIsEditing,
    noFrame,
    fakeApp,
    classNames,
    blockEntities,
    noEditButton,
    view,
}) {
    const pageContext = useMemo(
        () => ({
            ...context,

            theme,
            editor: {
                isEditing: false,
                actions: {},
            },
        }),
        [theme, context]
    )

    const handleUpdatePage = useCallback(
        (updates) => {
            updatePage({ id: page._sid, patch: updates })
        },
        [page._sid, updatePage]
    )

    return (
        <ActionsContextProvider object={object}>
            <Editor
                key={route}
                page={page}
                updatePage={handleUpdatePage}
                route={route}
                tree={tree}
                context={pageContext}
                isEditing={pageIsEditing}
                noEditButton={noEditButton}
            >
                {({ context, tree }) => (
                    <>
                        {!pageIsEditing ? <PanelFloatingButton /> : ''}
                        <div
                            id="page"
                            className={`device-${context.deviceType} ${
                                context.editor.isEditing && 'editing'
                            } ${classNames}`}
                            style={{
                                width: '100%',
                                minHeight: '100%',
                                overflow: 'auto',
                                transformOrigin: context.editor.isEditing ? '0 0 0' : 'none',
                                transform: context.editor.isEditing ? 'translate3d(0,0,0)' : 'none',
                            }}
                        >
                            {noFrame ? (
                                <BlockTree isOuter tree={tree} context={context} />
                            ) : (
                                <PageFrame
                                    context={pageContext}
                                    blockEntities={blockEntities}
                                    page={page}
                                    app={fakeApp}
                                    view={view}
                                >
                                    <BlockTree isOuter tree={tree} context={context} />
                                </PageFrame>
                            )}
                        </div>
                    </>
                )}
            </Editor>
        </ActionsContextProvider>
    )
})
const WithRouterPage = withObjects(withUser(Page))

export default WithRouterPage
