import React, { forwardRef } from 'react'

import { Box } from 'ui/components/Box'
import { Icon, IconNameVariantType } from 'ui/components/Icon/Icon'

import * as Parts from './Field.parts'
import { HelperText } from './HelperText'
import { InfoMark } from './InfoMark'
import { LabelText } from './LabelText'
import { OptionalMark } from './OptionalMark'
import { RequiredMark } from './RequiredMark'

type IconProps = IconNameVariantType

type FieldRef = HTMLDivElement

export type FieldProps = {
    htmlFor: string
    label?: string
    onLabelClick?: (e: React.MouseEvent<HTMLLabelElement>) => void
    isError?: boolean
    helperText?: string
    infoText?: string
    optional?: boolean
    rightSlotContent?: React.ReactNode
    leftSlotContent?: React.ReactNode
    required?: boolean
    disabled?: boolean
    startIcon?: IconProps
}

export const Field = forwardRef<FieldRef, React.ComponentPropsWithoutRef<typeof Box> & FieldProps>(
    (
        {
            htmlFor,
            children,
            label,
            onLabelClick,
            isError,
            helperText,
            infoText,
            optional,
            leftSlotContent,
            rightSlotContent,
            required,
            disabled,
            startIcon,
            ...props
        },
        ref
    ) => {
        const isLeftSlotEmpty = !label && !optional && !required && !infoText && !rightSlotContent

        return (
            <Parts.Wrapper role="group" ref={ref} {...props}>
                <Parts.LabelWrapper
                    isDisabled={disabled}
                    isEmpty={isLeftSlotEmpty}
                    hasMargin={!!children}
                >
                    <Parts.LeftSlotContent>
                        {startIcon && (
                            <Icon
                                aria-hidden={true}
                                size="m"
                                mr="xs"
                                {...startIcon}
                                color={disabled ? 'iconDisabled' : 'icon'}
                            />
                        )}
                        {label && (
                            <LabelText htmlFor={htmlFor} disabled={disabled} onClick={onLabelClick}>
                                {label}
                            </LabelText>
                        )}
                        {optional && <OptionalMark size="m" disabled={disabled} />}
                        {required && <RequiredMark size="m" disabled={disabled} />}
                        {infoText && (
                            <InfoMark size="xs" disabled={disabled}>
                                {infoText}
                            </InfoMark>
                        )}
                        {leftSlotContent}
                    </Parts.LeftSlotContent>
                    {rightSlotContent && (
                        <Parts.RightSlotContent>{rightSlotContent}</Parts.RightSlotContent>
                    )}
                </Parts.LabelWrapper>

                {children}
                {helperText && (
                    <HelperText
                        size="s"
                        isError={isError}
                        disabled={disabled}
                        hasMargin={!!children}
                    >
                        {helperText}
                    </HelperText>
                )}
            </Parts.Wrapper>
        )
    }
)
