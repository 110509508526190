import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { useAgentSkills } from 'data/hooks/agents/skills'
import { AgentSkill } from 'data/hooks/agents/types'
import { EditTools } from 'features/Agents/configuration/tools/EditTools'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'

import { EditEnabledSkills } from './EditEnabledSkills'

export function EditSkillForm({ agentSid, skill }: { agentSid: string; skill?: AgentSkill }) {
    const { register } = useFormContext<Partial<AgentSkill>>()
    const { data: skills = [] } = useAgentSkills(agentSid)

    const filteredSkills = useMemo(() => {
        return skills.filter((s) => s.api_name !== skill?.api_name)
    }, [skills, skill])

    return (
        <Box as="form" flex gap="l" flexGrow={1}>
            <Box flex grow column gap="l">
                <Field required label="Skill Name" width="5xl" shrink>
                    <Input
                        placeholder="Enter skill name"
                        {...register('name', { required: true })}
                    />
                </Field>

                <Field label="Description" width="5xl" shrink>
                    <Input placeholder="Enter skill description" {...register('description')} />
                </Field>
                <Textarea
                    required
                    flex
                    grow
                    label="Prompt"
                    placeholder="Enter skill prompt"
                    {...register('prompt', { required: true })}
                    fieldProps={{ style: { display: 'flex', flexDirection: 'column' } }}
                    style={{ display: 'flex', flexGrow: '1' }}
                />
            </Box>

            <Box flex style={{ width: '300px' }} column gap="l">
                <EditSkillInputs />
                <Field required label="API Name" width="5xl" shrink>
                    <Input
                        helperText="Editing the API name will break anything using it"
                        placeholder="Enter API name"
                        {...register('api_name', { required: true })}
                    />
                </Field>

                <EditEnabledSkills agentSid={agentSid} preventEdit skills={filteredSkills} />

                <EditTools agentSid={agentSid} />
            </Box>
        </Box>
    )
}

function EditSkillInputs() {
    const { register, setValue, watch } = useFormContext<Partial<AgentSkill>>()
    const inputs = watch('inputs') || []
    const [openInputs, setOpenInputs] = React.useState<number[]>([])

    const toggleInput = (idx: number) => {
        setOpenInputs((prev) =>
            prev.includes(idx) ? prev.filter((i) => i !== idx) : [...prev, idx]
        )
    }

    const removeInput = (idx: number) => {
        setValue(
            'inputs',
            inputs.filter((_, i) => i !== idx)
        )
        setOpenInputs((prev) => prev.filter((i) => i !== idx).map((i) => (i > idx ? i - 1 : i)))
    }

    return (
        <Field label="Inputs" shrink flex column gap="s">
            <Box flex column gap="s">
                {inputs?.map((input, idx) => (
                    <Box key={idx} flex column gap="s">
                        <Box
                            flex
                            center
                            gap="s"
                            onClick={() => toggleInput(idx)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Box flex center grow gap="s">
                                <Box
                                    style={{
                                        transform: openInputs.includes(idx)
                                            ? 'rotate(90deg)'
                                            : 'none',
                                        transition: 'transform 0.2s',
                                    }}
                                    flex
                                >
                                    <Icon name="ChevronRight" />
                                </Box>
                                {openInputs.includes(idx) ? (
                                    <Body color="textWeaker">Configure input {idx + 1}</Body>
                                ) : (
                                    <Box>
                                        {input.name ? (
                                            <Body weight="bold">{input.name}</Body>
                                        ) : (
                                            <Body color="textWeaker">Name</Body>
                                        )}
                                        :{' '}
                                        {input.description ? (
                                            <Body>{input.description}</Body>
                                        ) : (
                                            <Body color="textWeaker">Description</Body>
                                        )}
                                    </Box>
                                )}
                            </Box>

                            <Box
                                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                    e.stopPropagation()
                                    removeInput(idx)
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <Icon name="X" />
                            </Box>
                        </Box>

                        {openInputs.includes(idx) && (
                            <Box flex column gap="s" ml="l">
                                <Input
                                    size="m"
                                    {...register(`inputs.${idx}.name`)}
                                    placeholder="name"
                                />
                                <Input
                                    size="m"
                                    {...register(`inputs.${idx}.description`)}
                                    placeholder="description"
                                />
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
            <Button
                mt="s"
                variant="secondary"
                size="s"
                startIcon={{ name: 'Plus' }}
                onClick={() => {
                    const newIdx = inputs.length
                    setValue('inputs', [...inputs, { name: '', description: '' }])
                    setOpenInputs((prev) => [...prev, newIdx])
                }}
            >
                Add input
            </Button>
        </Field>
    )
}
