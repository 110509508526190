import { useLayoutEffect, useMemo, useRef } from 'react'

import { DetailViewSlimHeaderStickyStyle } from 'features/views/DetailView/headers/DetailViewHeader.css'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

import { useDetailViewTopBarState } from './useDetailViewTopBarState'

export function useDetailViewSlimHeaderState() {
    const { isPreviewing } = useDetailViewContext()!

    // Add a classname to the header when it's sticky.
    const headerRef = useRef<HTMLElement>(null)
    useLayoutEffect(() => {
        const scrollParent = document.querySelector('#contentWrapper')
        if (!scrollParent) return

        const scrollHandler = () => {
            const headerEl = headerRef.current
            if (!headerEl) return

            if (scrollParent.scrollTop > 0) {
                if (!headerEl.classList.contains(DetailViewSlimHeaderStickyStyle)) {
                    headerEl.classList.add(DetailViewSlimHeaderStickyStyle)
                }
            } else {
                headerEl.classList.remove(DetailViewSlimHeaderStickyStyle)
            }
        }
        scrollHandler()

        scrollParent.addEventListener('scroll', scrollHandler)
        return () => {
            scrollParent.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    const { isLoading } = useDetailViewTopBarState()

    return useMemo(() => ({ headerRef, isPreviewing, isLoading }), [isLoading, isPreviewing])
}
