import React from 'react'

import { SystemTool, ToolConfig } from 'data/hooks/agents/types'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

import { ToolAvatar } from './ToolAvatar'

type ToolListItemProps = BoxProps & {
    tool: SystemTool | ToolConfig
    onClear?: () => void
    onDelete?: () => void
}

export function ToolListItem({ tool, onClear, onDelete, ...props }: ToolListItemProps) {
    return (
        <Box flex role="button" gap="m" center border px="m" py="s" {...props}>
            <ToolAvatar toolId={('tool_id' in tool && tool.tool_id) || tool.api_name} />
            <Box flex column grow minWidth={0}>
                <Body weight="medium" trim>
                    {tool.name}
                    {/* <Tag size="xs">{tool.tool_id}</Tag> */}
                </Body>
                <Body size="s" trim style={{ minWidth: 0 }}>
                    {tool.description}
                </Body>
            </Box>
            {onClear && (
                <Button
                    variant="ghost"
                    size="xs"
                    iconOnly
                    title="Remove tool"
                    startIcon={{ name: 'X' }}
                    onClick={(e) => {
                        e.preventDefault()
                        onClear()
                    }}
                />
            )}
            {onDelete && (
                <Button
                    variant="ghost"
                    size="xs"
                    iconOnly
                    title="Delete tool"
                    startIcon={{ name: 'Trash' }}
                    onClick={(e) => {
                        e.preventDefault()
                        onDelete()
                    }}
                />
            )}
        </Box>
    )
}
