import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { FieldsWidgetAttributeField } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/types'

type BaseAttributeStateProps = {
    field: FieldsWidgetAttributeField
    showFieldIcon?: boolean
}

export function useBaseAttributeState({ field, showFieldIcon }: BaseAttributeStateProps) {
    const fieldIcon = showFieldIcon ? getFieldIcon(field.field) : undefined

    const emptyPlaceholder = getEmptyPlaceholder(field)

    return useMemo(() => ({ fieldIcon, emptyPlaceholder }), [fieldIcon, emptyPlaceholder])
}

function getEmptyPlaceholder(field: FieldsWidgetAttributeField) {
    const fieldLabel = field.field.label.toLowerCase()

    return !!fieldLabel ? `No ${fieldLabel}` : 'None'
}
